import ObjectUtils from 'wootils/shared/objectUtils';

/**
 * @typedef {Object} Feature
 * @property {Strong} id          The feature ID.
 * @property {String} name        The feature internal name.
 * @property {String} title       The feature name for display.
 * @property {String} description A small description of the feature.
 */

/**
 * Handles all the information related to customer accounts' features.
 */
class Features {
  /**
   * @param {AppAPI}           appAPI                    To make the requests for the
   *                                                     information.
   * @param {AppRequestsCache} appRequestsCache          To cache some of the generic
   *                                                     responses.
   * @param {AppUtils}         appUtils                  To sort response lists.
   * @param {Object}           BASIC_INFORMATION_FEATURE The settings of the _"basic
   *                                                     information feature"_, as it
   *                                                     doesn't exist on the API.
   */
  constructor(appAPI, appRequestsCache, appUtils, BASIC_INFORMATION_FEATURE) {
    'inject';

    /**
     * An _"static"_ feature that will be used to render the feature section for the
     * customer account basic information.
     * @type {Feature}
     */
    this.basicInformationFeature = ObjectUtils.extract(
      BASIC_INFORMATION_FEATURE,
      [
        'id',
        'name',
        'title',
        'description',
      ],
    );
    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     * @ignore
     */
    this._appAPI = appAPI;
    /**
     * A local reference for the `appRequestsCache` service.
     * @type {AppRequestsCache}
     * @access protected
     * @ignore
     */
    this._appRequestsCache = appRequestsCache;
    /**
     * A local reference for the `appUtils` service.
     * @type {AppUtils}
     * @access protected
     * @ignore
     */
    this._appUtils = appUtils;
    /**
     * A local reference for the `BASIC_INFORMATION_FEATURE` constant.
     * @type {Object}
     * @access protected
     * @ignore
     */
    this._BASIC_INFORMATION_FEATURE = BASIC_INFORMATION_FEATURE;
    /**
     * This is a dictionary with the default values of the settings from
     * `BASIC_INFORMATION_FEATURE` ordered in the same way a customer account would have them.
     * The idea is that when the app needs to create a new account basic information, it can
     * call `getBasicFeatureDefaults` to get a copy of this object.
     * @type {Object}
     * @access protected
     * @ignore
     */
    this._basicFeatureDefaults = this._createBasicInformationFeatureDefaults();
  }
  /**
   * Gets the list of features.
   * @return {Promise<Array,Error>}
   */
  getFeatures() {
    return this._appRequestsCache.save(
      'features.list',
      () => (
        this._appAPI.getFeaturesList()
        .then(({ features }) => [
          ObjectUtils.copy(this.basicInformationFeature),
          ...features.slice().sort(this._appUtils.sortObjectsList('title')),
        ])
      ),
    );
  }
  /**
   * Gets all the settings for the _"basic feature"_, the one that doesn't exist on the API but
   * that was created for the application to use for a customer basic information.
   * @return {Promise<Object,Error}
   */
  getBasicFeatureSettings() {
    return this.getFeatureSettings(this._BASIC_INFORMATION_FEATURE.id);
  }
  /**
   * Gets all the defaults for the settings of the _"basic feature"_. They have the same format as
   * if they were on a customer account information, so they can be used as a template for new
   * accounts.
   * @return {Object}
   */
  getBasicFeatureDefaults() {
    return ObjectUtils.copy(this._basicFeatureDefaults);
  }
  /**
   * Gets all the settings for a single feature.
   * @param  {String} featureId The feature ID.
   * @return {Promise<Object,Error}
   */
  getFeatureSettings(featureId) {
    return this._appRequestsCache.save(
      `features.byId.${featureId}`,
      () => {
        let result;
        if (featureId === this._BASIC_INFORMATION_FEATURE.id) {
          result = Promise.resolve(this._BASIC_INFORMATION_FEATURE);
        } else {
          result = this._appAPI.getFeatureById(featureId);
        }

        return result
        .then((feature) => this._parseFeature(feature));
      },
    );
  }
  /**
   * The unique ID for the _"basic feature"_.
   * @type {string}
   */
  get basicFeatureId() {
    return this._BASIC_INFORMATION_FEATURE.id;
  }
  /**
   * This method creates a dictionary with the default values of the settings from the
   * `BASIC_INFORMATION_FEATURE`. The way the method formats them is the same as if they were
   * on an existing customer account, so they can be used to _"simulate"_ a new account.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _createBasicInformationFeatureDefaults() {
    let result = {};
    const { sections: [{ settings }] } = this._BASIC_INFORMATION_FEATURE;
    settings.forEach((setting) => {
      if (typeof setting.defaultValue !== 'undefined') {
        let objPath;
        /**
         * If the setting name starts with `basic.`, it means that it goes on the root level of
         * the object, otherwise, it goes inside `settings`.
         */
        if (setting.name.startsWith('basic.')) {
          objPath = setting.name.replace(/^basic\./i, '');
        } else {
          objPath = `settings.${setting.name}`;
        }

        result = ObjectUtils.set(result, objPath, setting.defaultValue);
      }
    });

    return result;
  }
  /**
   * Updates all the sections of a feature by parsing and formatting all the settings
   * in order to be ready for the app to use.
   * @param {Object} feature The feature to parse.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _parseFeature(feature) {
    return Object.assign(
      {},
      feature,
      {
        sections: feature.sections.map((section) => this._parseSection(section)),
      },
    );
  }
  /**
   * Updates all the settings on a feature's section by parsing and formatting them
   * in order to be ready for the app to use..
   * @param {Object} section The section to parse.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _parseSection(section) {
    return Object.assign(
      {},
      section,
      {
        description: section.description === section.title ? '' : section.description,
        settings: section.settings.map((setting) => this._parseSetting(setting)),
      },
    );
  }
  /**
   * Updates a setting in order to be ready for the app to use.
   * @param {Object} setting The setting to update.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _parseSetting(setting) {
    let newSetting = Object.assign(
      {
        extras: {},
      },
      setting,
      {
        description: setting.description === setting.title ? '' : setting.description,
        readOnly: setting.visibility === 1,
      },
    );

    if (setting.type === 'input_text') {
      newSetting = this._parseInputSetting(newSetting);
    } else if (setting.type === 'input_password') {
      newSetting = this._parsePasswordSetting(newSetting);
    } else if (setting.type === 'select') {
      newSetting = this._parseSelectSetting(newSetting);
    }

    return newSetting;
  }
  /**
   * Updates a setting of the type `input_text` to it will be clear that it's a regular input. The
   * reason for this is because there are other kind of inputs, like `password`, which have the
   * same properties, but with different values (like `inputType` set to `password`) and since
   * some of those properties are created by this application (they're not on the API),
   * normalization is required.
   * @param {Object} setting The setting to update.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _parseInputSetting(setting) {
    return Object.assign(
      {},
      setting,
      {
        type: 'input_text',
        inputType: 'text',
        password: false,
      },
    );
  }
  /**
   * Updates a setting of the type `input_password` in order to change the type of input the
   * view should use (as this is an extended version of the `input_text` type).
   * @param {Object} setting The setting to update.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _parsePasswordSetting(setting) {
    return Object.assign(
      {},
      setting,
      {
        type: 'input_text',
        inputType: 'password',
        password: true,
      },
    );
  }
  /**
   * Updates a setting of the type `select` in order to format the dropdown values
   * and labels.
   * @param {Object} setting The setting to update.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _parseSelectSetting(setting) {
    const newSetting = Object.assign({}, setting);
    const options = Array.isArray(newSetting.options) ?
      newSetting.options :
      newSetting.options.split(',');

    newSetting.options = this._parseSelectSettingOptions(options);
    if (newSetting.extras && newSetting.extras.readOnlyOnExtraOptions) {
      newSetting.extras.readOnlyOnExtraOptions = this._parseSelectSettingOptions(
        newSetting.extras.readOnlyOnExtraOptions,
      );
    }

    return newSetting;
  }
  /**
   * Formats a list of options to be used on a select type setting.
   * For example:
   * - `['ACTIVE', ...]` -> { value: 'ACTIVE', label: 'ACTIVE', ...}
   * - `['ACTIVE:Active', ...]` -> { value: 'ACTIVE', label: 'Active', ...}
   * @param {Array} options The options to format.
   * @return {Array}
   * @access protected
   * @ignore
   */
  _parseSelectSettingOptions(options) {
    return options.map((option) => {
      let optionValue;
      let optionLabel;
      if (option.includes(':')) {
        [optionValue, optionLabel] = option.split(':').map((item) => item.trim());
      } else {
        optionValue = option.trim();
        optionLabel = optionValue;
      }

      if (['true', 'false'].includes(optionValue)) {
        optionValue = optionValue === 'true';
      }

      return {
        value: optionValue,
        label: optionLabel,
      };
    });
  }
}

export { Features };
