import { PLATFORM } from 'aurelia-pal';
/**
 * The definition of all the routes of the app.
 * @type {AppNavigation}
 */
export const NAVIGATION = {
  avatar: [
    {
      name: 'logout',
      route: ['logout'],
      moduleId: PLATFORM.moduleName('aurelio/containers/logout/logout.container'),
      title: 'Logout',
      settings: {
        hideMenu: true,
      },
    },
  ],
  main: [],
  sub: [],
  unmapped: [
    {
      name: 'home',
      route: ['', 'accounts/:accountId?/:feature?'],
      moduleId: PLATFORM.moduleName(
        'modules/accounts/containers/accounts/accounts.container',
      ),
      title: 'Accounts',
    },
    {
      name: 'accounts',
      route: ['accounts/:accountId?/:feature?'],
      moduleId: PLATFORM.moduleName(
        'modules/accounts/containers/accounts/accounts.container',
      ),
      title: 'Accounts',
    },
    {
      name: 'login',
      route: ['login'],
      moduleId: PLATFORM.moduleName('aurelio/containers/login/login.container'),
      title: 'Login',
      settings: {
        hideMenu: true,
        requiresAuthorization: false,
      },
    },
  ],
};
