import { bindable, computedFrom } from 'aurelia-framework';
import { boundMethod } from 'autobind-decorator';
import ObjectUtils from 'wootils/shared/objectUtils';
/**
 * This is the form the users can use to edit a customer account settings for an specific feature.
 */
class FeatureForm {
  /**
   * Whether or not the component should show the loading indicator.
   * @type {Boolean}
   */
  @bindable loading = false;
  /**
   * Whether or not to show the form title.
   * @type {Boolean}
   */
  @bindable showTitle = true;
  /**
   * Whether or not the component is inside a modal. When this is `true`, the view will apply
   * specific style rules to adjust the size of the form.
   * @type {Boolean}
   */
  @bindable onModal = false;
  /**
   * Whether or not the component should make use of the feature `readonly` properties. This
   * is usually set to `false` if the form is being used to create settings instead of reading
   * them.
   * @type {Boolean}
   */
  @bindable useReadOnly = true;
  /**
   * Whether or not the form is being used to edit an existing customer account. Enabling or
   * disabling this can make the form change the behavior of some of the settings.
   * @type {Boolean}
   */
  @bindable editMode = true;
  /**
   * The name of the customer account to edit. Although we also get this inside `settings`, the
   * `settings` object can be resolved async, so while the component waits for it to be ready,
   * we can show the name of the account.
   * @type {String}
   */
  @bindable accountName = '';
  /**
   * This works similar to the `accountName` binding, we also get it from the `settings`, but
   * the vie will show it on the title while the component waits for `setting` to be ready.
   * @type {Number}
   */
  @bindable accountId = 0;
  /**
   * The same as `accountName` and `accountId`, this will eventually be available on the `feature`
   * binding, but the view will show it while it waits for `feature` to be ready.
   * Now, there's a _"special"_ difference for this one: The user can navigate to another feature
   * without changing the account. So this binding is also used by the component in order to know
   * when the information the view uses for the settings should be resetted. _'Resetted'_
   * because the component does some custom formatting to the feature settings definition in order
   * to get them ready for the view, and this formatting should only happen when the feature
   * changes.
   * @type {String}
   */
  @bindable featureTitle = '';
  /**
   * All the information about the feature the form will show fields for.
   * @type {Object}
   */
  @bindable feature = {};
  /**
   * The customer account information.
   * @type {Object}
   */
  @bindable account = {};
  /**
   * Whether or not to show the button to deactiavte a customer account.
   * @type {Boolean}
   */
  @bindable showDeactivateButton = false;
  /**
   * The callback invoked when the user inteds to deactivate a customer account.
   * @type {Function}
   */
  @bindable onDeactivate = () => {};
  /**
   * A dictionary with validations for Salesforce IDs. When the user wants to validate an ID, it
   * sends that ID to the implementation, that handles the validation process, and then updates
   * this dictionary by setting a key with the ID and the validation response as the value.
   * @type {Object}
   */
  @bindable salesforceValidations = {};
  /**
   * Whether or not the information from the form was successfully saved. This help the component
   * as it internally saves the changes before calling `onSave` and if `success` changes to `true`
   * it applies those changes to the settings as their current value, so any further modification
   * will be handled as a new change... all of this because the submit button is only enable when
   * there are changes to be saved.
   * @type {Boolean}
   */
  @bindable success = false;
  /**
   * This is a callback the component invokes when there's a problem inferring the field type or
   * value of a setting.
   * @type {Function(message:String)}
   */
  @bindable onFieldDetectionWarning = () => {};
  /**
   * This is a callback the component invokes when the user wants to validate a Salesforce
   * account ID.
   * @type {Function(value:String)}
   */
  @bindable onSalesforceValidation = () => {};
  /**
   * This is the callback the component invokes when the user has made changes to the account
   * information and he/she wants to save them. The callback only receives the dictionary with
   * the changes.
   * @type {Function(changes:Object)}
   */
  @bindable onSave = () => {};
  /**
   * After the component formats the feature settings, it creates _"fields"_, which are the set
   * of setting definition and value, plus some extra properties for special cases
   * (like checkboxes with non-boolean values). These fields are stored by name and by section
   * name on this object.
   * For example `{ [sectionName]: { [fieldOneName]: ..., [fieldTwoName]: ... }}`, as it makes it
   * easier for the view to read them without having to modify the whole `feature` object.
   * @type {Object}
   */
  fieldsBySection = {};
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * When the user submits the form, the component will evaluate which fields are empty and save
   * their name on this dictionary with `true` as a value. The idea is for the view to easily
   * check for `emptyFields[field.name]` instead of having to do an `includes`.
   * @type {Object}
   */
  emptyFields = {};
  /**
   * Please read the description for `emptyFields` first. Now, this flag just tells the view if
   * the emptyFields dictionary is empty or not.
   * @type {Boolean}
   */
  hasEmptyFields = false;
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * Now, this list saves a reference for each one of the fields, so when the user submits the
   * form, the component just loops this list in order to find changes to the values.
   * @type {Array}
   */
  _allFields = [];
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * When the user changes the value of a field, the component will check if the current value is
   * different from the original one and if it is, it will save the field name on this list.
   * The idea is to enable or disable the _"save button"_ when there actual changes to save.
   * @type {Array}
   * @access protected
   * @ignore
   */
  _changedFields = [];
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This flag tells the component whether or not the _"fields"_ where created. Whenever the
   * feature title changes (which means the feature will eventually change), the flag is
   * resetted.
   * @type {Boolean}
   * @access protected
   * @ignore
   */
  _formattedFields = false;
  /**
   * The prefix for the name of the settings that aren't actual custom settings, but basic
   * information of the customer account.
   * @type {String}
   * @access protected
   * @ignore
   */
  _basicPrefix = 'basic.';
  /**
   * Whether or not the form sent a Salesforce ID to the implementation to be validated. When
   * this property is set to `true`, it also disables the save button.
   * @type {Boolean}
   * @access protected
   * @ignore
   */
  _validatingSalesforce = false;
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * Whether or not the user tried to save the information at least once. The reason for this is
   * so the form will highlight required-empty-fields only after the first submit.
   * @type {Boolean}
   * @access protected
   * @ignore
   */
  _atLeastOneSaveAttempt = false;
  /**
   * Since most of the customer account settings are not normalized, when a setting is of the type
   * `checkbox`, the component uses this dictionary to determine the type of boolean a setting is
   * using. It loops all the possible modes (types) and if the settings has either the `true` or
   * `false` value, it uses that mode to check if the value is equal to the `true` value.
   * @type {Object}
   * @access protected
   * @ignore
   */
  _checkboxModes = {
    boolean: {
      true: true,
      false: false,
    },
    stringBoolean: {
      true: 'true',
      false: 'false',
    },
    numericBoolean: {
      true: 1,
      false: 0,
    },
    numericString: {
      true: '1',
      false: '0',
    },
    enabledDisabled: {
      true: 'enabled',
      false: 'disabled',
    },
    onOff: {
      true: 'on',
      false: 'off',
    },
  };
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * Whenever the form calls the `onSave` binding, this property will become a list with the
   * changes the user is trying to save. The list will have an `index` property with the field
   * `index` on the `_allFields` list and a `value` property with the field new value.
   * This property plays with the `success` binding; when `success` changes from `false` to
   * `true`, the component will take all the values from this list and set them as the fields
   *  `originalValue` property, so further modifications will be handled as new changes.
   * @type {?Array}
   * @access protected
   * @ignore
   */
  _currentChanges = null;
  /**
   * Whenever the feature title changes, which means that feature settings are being resolved,
   * the component turns on a flag so when the feature is resolved, it knowns that it has to apply
   * (or re apply) the custom formatting for the settings.
   */
  featureTitleChanged() {
    this._formattedFields = false;
  }
  /**
   * Whenever the account name changes, which means that account settings are being resolved,
   * the component turns on a flag so when the account is resolved, it knowns that it has to apply
   * (or re apply) the custom formatting for the settings.
   */
  accountNameChanged() {
    // Force clearing validations
    this.emptyFields = {};
    this._changedFields = [];
    this.hasEmptyFields = false;
    this._formattedFields = false;
  }
  /**
   * Whenever the feature changes, apply the settings custom formatting.
   */
  featureChanged() {
    this._formatFields();
  }
  /**
   * Whenever the account changes, or its status changes, apply the custom formatting.
   */
  accountChanged(newAccount, prevAccount) {
    if (
      newAccount &&
      prevAccount &&
      newAccount.id === prevAccount.id &&
      newAccount.status !== prevAccount.status
    ) {
      this.accountNameChanged();
    }

    this._formatFields();
  }
  /**
   * Whenever the `success` binding changes from `false` to `true`, if there are changes saved,
   * the component will take those values and set them as the settings current values, so any
   * further modification will be handled as a new change.
   * @param {Boolean} currentValue  The current value of the binding.
   * @param {Boolean} previousValue The previous value of the binding.
   */
  successChanged(currentValue, previousValue) {
    if (currentValue && !previousValue && this._currentChanges && this._currentChanges.length) {
      this._currentChanges.forEach(({ index, value }) => {
        this._allFields[index].originalValue = value;
      });
      this._currentChanges = null;
      this._changedFields = [];
    }
  }
  /**
   * This is called every time the dictionary with Salesforce validation changes. The method just
   * loops and finds the `input_salesforce` fields in order to update their properties.
   */
  salesforceValidationsChanged() {
    if (this._validatingSalesforce && this.salesforceValidations) {
      Object.keys(this.salesforceValidations).forEach((salesforceId) => {
        const validation = this.salesforceValidations[salesforceId];
        this._allFields
        .filter((field) => field.salesforce && field.value === salesforceId)
        .forEach((field) => {
          field.loading = false;
          if (validation === false) {
            field.actionMessage = {
              success: false,
              text: 'No account was found for this ID',
            };
          } else if (validation) {
            field.actionMessage = {
              success: true,
              text: `<strong>Valid account:</strong> ${validation.Name} - ${validation.Type}`,
            };
          }
        });
      });

      this._validatingSalesforce = false;
    }
  }
  /**
   * This is called from the dropdown elements used for the settings of type `select`. It just
   * updates the setting value.
   * @param {Object} field The setting field. Refer to the `fieldsBySection` documentation to
   *                       understant what _"fields"_ are.
   * @param {Object} item  The dropdown selected item.
   */
  selectItemFromDropdown(field, item) {
    field.value = item.value;
    this.fieldChanged(field);
  }
  /**
   * This is called every time a component that don't have access to a binding model changes its
   * vale. The method just takes that value and updates the field with it.
   * @param {Object} field The setting field. Refer to the `fieldsBySection` documentation to
   *                       understant what _"fields"_ are.
   * @param {*}      value The field new value.
   */
  updateFieldValue(field, value) {
    field.value = value;
    this.fieldChanged(field);
  }
  /**
   * This is called from an `input_salesforce` field in order to validate a Salesforce ID. The
   * method just _"turns on"_ the component flag and invokes the callback.
   * @param {Object} field The setting field. Refer to the `fieldsBySection` documentation to
   *                       understant what _"fields"_ are.
   * @param {String} value The ID to validate.
   */
  validateSalesforce(field, value) {
    if (!this._validatingSalesforce) {
      this._validatingSalesforce = true;
      field.loading = true;
      this.onSalesforceValidation({ value });
    }
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is called every time a field value changes. This method takes care of doing the
   * necessary stuff in oder to update the field's _"changed"_ and _"empty"_ statuses, which are
   * used for enabling/disabling the save button where there are no changes, and highlight the
   * field when is required but has no value.
   * @param {Object} field The information of the field that changed.
   */
  fieldChanged(field) {
    this._checkChangedField(field);
    this._checkRequiredField(field);
  }
  /**
   * This is called when the user submits the form. It detects what settings where changed from
   * their original values and calls the implementation.
   * @param {Event} $event The event triggered by the submit. The method uses it to prevent the
   *                       default behavior.
   */
  save($event) {
    $event.preventDefault();
    this._atLeastOneSaveAttempt = true;
    let atLeastOneEmpty = false;
    const emptyFields = {};

    this._allFields.forEach((field) => {
      if (field.required && field.value === '') {
        atLeastOneEmpty = true;
        emptyFields[field.settingName] = true;
      }
    });

    this.emptyFields = emptyFields;
    this.hasEmptyFields = atLeastOneEmpty;

    if (!atLeastOneEmpty) {
      let atLeastOneChange = false;
      const currentChanges = [];
      const changes = this._allFields
      .reduce((acc, field, index) => {
        let nextAcc;
        if (field.value !== field.originalValue) {
          atLeastOneChange = true;
          const { name, value } = field;
          const fieldPath = field.basic ? name : `settings.${name}`;
          nextAcc = ObjectUtils.set(acc, fieldPath, value);
          currentChanges.push({
            index,
            value,
          });
        } else {
          nextAcc = acc;
        }

        return nextAcc;
      }, {});

      if (atLeastOneChange) {
        this._currentChanges = currentChanges;
        this.onSave({ changes });
      }
    }
  }
  /**
   * Whether or not the save button should be disabled.
   * @type {Boolean}
   */
  @computedFrom('loading', 'hasEmptyFields', 'account', '_changedFields', '_validatingSalesforce')
  get disableSaveButton() {
    return this._validatingSalesforce ||
      this.loading ||
      this.hasEmptyFields ||
      !this._changedFields.length ||
      (this.account && !this.account.status);
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This method first validates that the flag for fomatting is set to `false` and that both the
   * `account` and the `feature` are resolved; then it loops all the feture's sections and their
   * settings in order to create the _"fields"_.
   * @access protected
   * @ignore
   */
  _formatFields() {
    if (
      !this._formattedFields &&
      this.feature &&
      this.feature.name &&
      this.account &&
      this.account.status
    ) {
      // Change the flag.
      this._formattedFields = true;
      // Reset the flag of saving attempts.
      this._atLeastOneSaveAttempt = false;
      // Set the list of _"formatters"_ the method will use.
      const formatters = [
        this._formatFieldByName,
        this._formatFieldValue,
        this._formatCheckboxField,
        this._formatSalesforceField,
        this._formatFieldDescription,
        this._formatFieldOriginalValue,
        this._formatSelectField,
        this._formatFieldOnEditMode,
      ];
      // Reset the fields.
      this._allFields = [];
      // Loop all the sections and their settings.
      this.feature.sections.forEach((section) => (
        section.settings
        .filter((setting) => (
          (!this.editMode && !setting.extras.hideOnCreate) ||
          (this.editMode && !setting.extras.hideOnEdit)
        ))
        .forEach((setting) => {
          // Reduce the setting using the _"formatters"_ in order to create the field.
          const field = formatters.reduce(
            (acc, formatter) => formatter(acc),
            ObjectUtils.merge(setting, {
              /**
               * Save the name of the setting to use as an _"ID"_ so the component can use it to save
               * metadata (like the emptyFields flag) using it.
               */
              settingName: setting.name,
              // Update the `readOnly` flag by evalutating the component property.
              readOnly: this.useReadOnly && setting.readOnly,
            }),
          );

          // Create the dictionary for the section if it doesn't exist.
          if (!this.fieldsBySection[section.name]) {
            this.fieldsBySection[section.name] = {};
          }
          // Add the field to the section dictionary.
          this.fieldsBySection[section.name][setting.name] = field;
          // Add the field to the the full list.
          this._allFields.push(field);
        })
      ));
    }
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method checks and updates the field name so the component will know whether the value
   * comes from the account information, the account settings or even a nested object inside one
   * of them.
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatFieldByName(field) {
    const newField = Object.assign({}, field);
    if (newField.name.startsWith(this._basicPrefix)) {
      /**
       * If the setting is for something outside the `settings` dictionary, remove the prefix
       * and add the `basic` flag.
       */
      newField.name = newField.name.substr(this._basicPrefix.length);
      newField.basic = true;
    } else if (newField.name.match(/^\w+\[\w+\]$/)) {
      /**
       * If the setting matches `name[subname]`, it means that the value is from inside an
       * object, so we reformat it for `ObjectUtils.get`. We also save the original name
       * on a property so the `save` method can restore it later.
       */
      newField.originalName = newField.name;
      newField.name = newField.name.replace(/^(\w+)\[(\w+)\]$/, '$1.$2');
    }

    return newField;
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method uses the field name to correctly set the current value from the account.
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatFieldValue(field) {
    const newField = Object.assign({}, field);
    if (newField.password) {
      newField.value = '';
    } else if (newField.basic) {
      newField.value = this.account[newField.name];
    } else {
      const value = ObjectUtils.get(this.account.settings, newField.name);
      if (typeof value === 'undefined') {
        newField.value = newField.default;
      } else {
        newField.value = value;
      }
    }

    return newField;
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method just adds a `originalValue` property with the current value of the field. The
   * reason this is on a separated method is because is possible that another formatter would
   * modify the value after being set by `_formatFieldValue`.
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatFieldOriginalValue(field) {
    return Object.assign({}, field, {
      originalValue: field.value,
    });
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method only modifies fields of the type `select` by checking if they have a _"valid
   * default value"_: If the current value is `null` or `undefined`, set the value as the first
   * item of the list of options.
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatSelectField(field) {
    let newField;
    if (
      field.type === 'select' &&
      (
        field.value === null ||
        typeof field.value === 'undefined'
      ) &&
      field.options.length
    ) {
      newField = Object.assign({}, field, {
        value: field.options[0].value,
      });
    } else {
      newField = field;
    }

    return newField;
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method only modifies fields of the type `input_salesforce` in order to add the required
   * properties for an {@link InputWithAction} component.
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatSalesforceField(field) {
    let newField;
    if (field.type === 'input_salesforce') {
      newField = Object.assign({}, field, {
        type: 'input_with_action',
        actionMessage: {},
        salesforce: true,
        loading: false,
      });
    } else {
      newField = field;
    }

    return newField;
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method checks and apply changes defined on the field for when the form is on _"edit
   * mode"_ (`editMode` binding).
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatFieldOnEditMode(field) {
    let newField;
    if (this.editMode) {
      newField = Object.assign({}, field);
      // - Change the `required` flag.
      if (typeof newField.extras.requiredOnEdit !== 'undefined') {
        newField.required = newField.extras.requiredOnEdit;
      }
      // - Change the `readOnly` flag.
      if (newField.extras.readOnlyOnEditWithValues) {
        newField.readOnly = newField.extras.readOnlyOnEditWithValues.includes(newField.value);
      }

      if (newField.extras.readOnlyOnExtraOptions) {
        newField.extras.readOnlyOnExtraOptions.some((extraOption) => {
          /**
           * We are using a different variable to track the loop because if we use `readOnly`,
           * if it's already `true`, it will only run once... but we need the option to prevent
           * the setting to be disabled with an option that's not present.
           */
          let breakLoop = false;
          if (newField.value === extraOption.value) {
            newField.readOnly = true;
            breakLoop = true;
            if (!newField.options.some((option) => option.value === extraOption.value)) {
              newField.options.push(extraOption);
            }
          }

          return breakLoop;
        });
      }

      if (newField.extras.extraValuesOneEdit) {
        newField.options = [...newField.options, ...newField.extras.extraValuesOneEdit];
      }
    } else {
      newField = field;
    }

    return newField;
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method only modifies fields of the type `input_checkbox` by identifying the _"type of
   * boolean value"_ a field is using. The reason for this is that some settings use
   * `enabled/disabled`, `on/off`, `1/0` or even `'true'/'false'`.
   * If the mode can't be indentified, it fallbacks to basic boolean and informs the
   * implementation.
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatCheckboxField(field) {
    let newField;
    if (field.type === 'input_checkbox') {
      let fieldMode = Object.keys(this._checkboxModes)
      .find((modeName) => {
        const mode = this._checkboxModes[modeName];
        return [mode.true, mode.false].includes(field.value);
      });

      if (!fieldMode) {
        this.onFieldDetectionWarning({
          message: 'Unable to identify checkbox mode. field: ' +
            `${field.name} - value: ${field.value}`,
        });
        fieldMode = 'boolean';
      }

      newField = Object.assign({}, field, {
        value: field.value === this._checkboxModes[fieldMode].true,
        mode: fieldMode,
      });
    } else {
      newField = field;
    }

    return newField;
  }
  /**
   * Please read the description for `fieldsBySection` in order to understand what _"fields" are.
   * This is on of the _"formatters"_ the `_formatFields` method uses in order to create the
   * fields.
   * This method formats a filed description in order to remove _"weird styling properties"_, as
   * the descriptions support HTML.
   * @property {Object} field The field current properties.
   * @return {Object} A new and updated version of the field.
   * @access protected
   * @ignore
   */
  @boundMethod
  _formatFieldDescription(field) {
    let newField;
    if (field.description) {
      // Create a new reference in order to update it.
      let newDescription = field.description;
      // Find all the style attributes
      const styles = [];
      const regex = /style=['|"].*?['|"][ |>]/gi;
      let match = regex.exec(newDescription);
      while (match) {
        const [attribute] = match;
        styles.push({ attribute });
        match = regex.exec(newDescription);
      }
      // Replace all the invalid properties.
      styles.forEach((style) => {
        const newAttribute = style.attribute
        // Anything related to `margin`.
        .replace(/\s*margin(?:-(?:top|bottom|left|right))?\s*:\s*\d+\w{2,3}\s*(?:;)?/g, '')
        // Anything related to `padding`.
        .replace(/\s*padding(?:-(?:top|bottom|left|right))?\s*:\s*\d+\w{2,3}\s*(?:;)?/g, '')
        // Anything related to `display`.
        .replace(/\s*display\s*:\s*[\w-]+\s*(?:;)?/g, '')
        // Color formatting that makes the text difficult to read.
        .replace(/\s*color\s*:\s*#8{3}(?:8{3})?\s*(?:;)?/g, '');
        // Update the description.
        newDescription = newDescription.replace(
          style.attribute,
          newAttribute,
        );
      });

      newField = Object.assign({}, field, {
        description: newDescription,
      });
    } else {
      newField = field;
    }

    return newField;
  }
  /**
   * This is called after a field value changes. This method takes care of detecting if the
   * current value is different from the original one in order to save it or not on the list of
   * fields that changed, thus enabling or disabling the save button (as there's no need to save
   * if the user didn't change anything).
   * @param {Object} field The information of the field that changed.
   * @access protected
   * @ignore
   */
  _checkChangedField(field) {
    const hasChanged = field.value !== field.originalValue;
    const isOnTheList = this._changedFields.includes(field.settingName);
    if (hasChanged && !isOnTheList) {
      this._changedFields = [...this._changedFields, field.settingName];
    } else if (!hasChanged && isOnTheList) {
      this._changedFields = this._changedFields
      .filter((settingName) => settingName !== field.settingName);
    }
  }
  /**
   * This is called after a field value changes. This method checks if the user already attempted
   * to save the form and if the field is marked as empty (what would mean that the user is seeing
   * the required empty fields highlighted with error) in order to update its _"empty status"_.
   * @param {Object} field The information of the field that changed.
   * @access protected
   * @ignore
   */
  _checkRequiredField(field) {
    if (this._atLeastOneSaveAttempt && field.required) {
      const hasValue = field.value !== '';
      const onTheEmptyList = this.emptyFields[field.settingName];
      let newEmptyFields;
      if (hasValue && onTheEmptyList) {
        newEmptyFields = Object.assign({}, this.emptyFields);
        delete newEmptyFields[field.settingName];
      } else if (!hasValue && !onTheEmptyList) {
        newEmptyFields = Object.assign({}, this.emptyFields, {
          [field.settingName]: true,
        });
      }

      if (newEmptyFields) {
        this.emptyFields = newEmptyFields;
        this.hasEmptyFields = !!Object.keys(this.emptyFields)
        .filter((key) => typeof this.emptyFields[key] !== 'undefined')
        .length;
      }
    }
  }
}

export { FeatureForm };
