import { APIClient } from 'aurelio/src/abstracts/apiClient';

// To avoid no-magic-numbers.
const DEFAULT_PAGINATION_COUNT = 20;

/**
 * The app API client. It takes care of making all the requests.
 * @extends {APIClient}
 */
class AppAPI extends APIClient {
  /**
   * @param {HttpClient}       httpClient       The Aurelia's HTTP client, to make the requests.
   * @param {AppConfiguration} appConfiguration To get the API configuation.
   */
  constructor(httpClient, appConfiguration) {
    'inject';

    super(httpClient, appConfiguration.get('api'));
  }
  /**
   * Gets a list of customer accounts.
   * @param {Number} page                             The pagination page number.
   * @param {String} [search='']                      An option search query to filter the
   *                                                  accounts.
   * @param {Number} [count=DEFAULT_PAGINATION_COUNT] How many accounts should be returned.
   * @return {Promise<Object,Error>}
   */
  getCustomerAccountsList(page, search = '', count = DEFAULT_PAGINATION_COUNT) {
    const parameters = { page, count };
    if (search) {
      parameters.search = search;
    }

    return this.get(this.endpoint('customerAccounts.list', parameters));
  }
  /**
   * Gets a customer account _"extract"_. The extract is just the customer name and ID.
   * This is usually used on lists where you don't need the entire entity.
   * @param {Number} accountId The customer account ID.
   * @return {Promise<Object,Error>}
   */
  getCustomerAccountExtract(accountId) {
    return this.get(this.endpoint('customerAccounts.extract', { accountId }));
  }
  /**
   * Gets a single customer account information.
   * @param {Number} accountId The customer account ID.
   * @return {Promise<Object,Error>}
   */
  getCustomerAccount(accountId) {
    return this.get(this.endpoint('customerAccounts.byId', { accountId }));
  }
  /**
   * Creates a new customer account.
   * @param {Object} account The account information.
   * @return {Promise<Object,Error>}
   */
  createCustomerAccount(account) {
    return this.post(
      this.endpoint('customerAccounts.new'),
      { account },
    );
  }
  /**
   * Updates a customer account information.
   * @param {Number} accountId The customer account ID.
   * @param {Object} account   The account information.
   * @return {Promise<Object,Error>}
   */
  updateCustomerAccount(accountId, account) {
    return this.post(
      this.endpoint('customerAccounts.update', { accountId }),
      { account },
    );
  }
  /**
   * Deactivates a customer account.
   * @param {Number} accountId The customer account ID.
   * @return {Promise<Object,Error>}
   */
  deactivateCustomerAccount(accountId) {
    return this.delete(
      this.endpoint('customerAccounts.deactivate', { accountId }),
    );
  }
  /**
   * Gets the list of customer accounts features.
   * @return {Promise<Object,Error>}
   */
  getFeaturesList() {
    return this.get(this.endpoint('features.list'));
  }
  /**
   * Gets all the settings for a single feature.
   * @param  {Number} featureId The feature ID.
   * @return {Promise<Object,Error>}
   */
  getFeatureById(featureId) {
    return this.get(this.endpoint('features.byId', { featureId }));
  }
  /**
   * Gets the information of a Salesforce account by its ID.
   * @param {String} salesforceId The account ID.
   * @return {Promise<Object,undefined>}
   */
  getSalesforceAccount(salesforceId) {
    return this.get(this.endpoint('salesforce.getAccount', { salesforceId }));
  }
  /**
   * Gets a list of activities for a customer account.
   * @param {Number} accountId                        The customer account ID.
   * @param {Number} page                             The pagination page number.
   * @param {Number} [count=DEFAULT_PAGINATION_COUNT] How many activities should be returned.
   * @return {Promise<Object,Error>}
   */
  getAccountActivities(accountId, page, count = DEFAULT_PAGINATION_COUNT) {
    return this.get(this.endpoint('customerAccounts.activities', {
      accountId,
      page,
      count,
    }));
  }
}

export { AppAPI };
