/**
 * @typedef {Object} AccountInformation
 * @property {String} name The account name.
 * @property {Number} id   The account ID.
 */

/**
 * A helper service used by the `accounts` module in order to validate and store
 * information about the selected account.
 */
class SelectedAccountOptions {
  /**
   * The key the service will use on the session store in order to save the
   * selected account information.
   * @type {String}
   * @access protected
   * @ignore
   */
  _storeKey = 'selected-account-option';
  /**
   * The ID of the selected account.
   * @type {?Number}
   * @access protected
   * @ignore
   */
  _accountId = null;
  /**
   * @param {AppSessionStore}  appSessionStore  To store the selection.
   * @param {CustomerAccounts} customerAccounts To get an account information if it's not on the
   *                                            store.
   */
  constructor(appSessionStore, customerAccounts) {
    'inject';

    /**
     * A local reference for the `appSessionStore` service.
     * @type {AppSessionStore}
     * @access protected
     * @ignore
     */
    this._appSessionStore = appSessionStore;
    /**
     * A local reference for the `customerAccounts` service.
     * @type {CustomerAccounts}
     * @access protected
     * @ignore
     */
    this._customerAccounts = customerAccounts;

    this._loadInitialState();
  }
  /**
   * Selects an account and store its information.
   * @param {AccountInformation} accountInformation The selected account/option
   *                                                information.
   */
  select(accountInformation) {
    this._appSessionStore.setWithKey(this._storeKey, Object.assign(
      {},
      accountInformation,
    ));

    this._accountId = accountInformation.id;
  }
  /**
   * Removes the selected account information.
   */
  unselect() {
    this._accountId = null;
    this._appSessionStore.deleteKey(this._storeKey);
  }
  /**
   * Validates an account ID to make sure the service has the information for it.
   * @param {Number} accountId The account ID.
   * @return {Promise<AccountInformation,Error>}
   */
  validate(accountId) {
    let result;
    const hasSavedState = this._accountId !== null;
    const savedStateMatchesId = hasSavedState && accountId === this._accountId;

    if (hasSavedState && savedStateMatchesId) {
      /**
       * The user is trying to read an account and the information is already saved on
       * the store, so just set to return an already resolved promise.
       */
      result = Promise.resolve();
    } else {
      /**
       * The user is trying to read an account and there's nothing saved on the store, or what
       * it's saved doesn't match.
       *
       * First, if there's something on the store, we delete it.
       */
      if (hasSavedState) {
        this._appSessionStore.deleteKey(this._storeKey);
      }
      // Then we request the account information and call `select` with it.
      result = this._loadAccountInformation(accountId)
      .then((accountInformation) => {
        this.select(accountInformation);
      });
    }

    return result
    .then(() => this._appSessionStore.getFromKey(this._storeKey));
  }
  /**
   * The ID for the customer account currently selected.
   * @type {?Number}
   */
  get accountId() {
    return this._accountId;
  }
  /**
   * Whether or not the service is requesting information.
   * @type {Boolean}
   */
  get loading() {
    return this._loading;
  }
  /**
   * Loads the saved information from the session store.
   * @access protected
   * @ignore
   */
  _loadInitialState() {
    const saved = this._appSessionStore.getFromKey(this._storeKey);
    if (saved) {
      this._accountId = saved.id;
    }
  }
  /**
   * Loads the information fo a single customer account.
   * @param {Number} accountId The account ID.
   * @return {Promise<AccountInformation,Error>}
   * @access protected
   * @ignore
   */
  _loadAccountInformation(accountId) {
    this._loading = true;
    return this._customerAccounts.getCustomerAccountExtract(accountId)
    .then((account) => {
      this._loading = false;
      return account;
    })
    .catch((error) => {
      this._loading = false;
      return Promise.reject(error);
    });
  }
}

export { SelectedAccountOptions };
