import { bindable, computedFrom } from 'aurelia-framework';

/**
 * @typedef {Object} InputWithActionMessage
 * @property {Boolean}  success Whether or not the action was a success. If the value is `false`,
 *                              the input and the message text will be highlighted the same way that
 *                              when the input has `error` set to `true`.
 * @property {String}   text    The message content.
 * @property {?Boolean} show    Whether or not to show the message at all.
 */

// To avoid no-magic-numbers
const defaultMaxLength = 255;
const defaultLetterWidth = 10;
/**
 * This component is basically an input field with an actionable link on the right.
 */
class InputWithAction {
  /**
   * The ID of the input.
   * @type {String}
   */
  @bindable id = '';
  /**
   * The name of the input.
   * @type {String}
   */
  @bindable name = '';
  /**
   * The placeholder for the input.
   * @type {String}
   */
  @bindable placeholder = '';
  /**
   * The autocomplete property for the input.
   * @type {String}
   */
  @bindable autocomplete = 'disabled';
  /**
   * The type of input.
   * @type {String}
   */
  @bindable type = 'text';
  /**
   * The input value.
   * @type {String}
   */
  @bindable value = '';
  /**
   * The max length of characters the user can write in the input.
   * @type {Number}
   */
  @bindable maxLength = defaultMaxLength;
  /**
   * Whether or not the input is doing an async task, so the loading indicator should be visible.
   * To be aware that when `loading` is `true`, the input is also disabled.
   * @type {Boolean}
   */
  @bindable loading = false;
  /**
   * Whether or not to highlight the input as if it had an error.
   * @type {Boolean}
   */
  @bindable error = false;
  /**
   * Whether or not the input is disabled.
   * @type {Boolean}
   */
  @bindable disabled = false;
  /**
   * The text for the action button.
   * @type {String}
   */
  @bindable action = 'Validate';
  /**
   * In case the action is some sort of validation, this object can be used to send the results.
   * @type {?InputWithActionMessage}
   */
  @bindable actionMessage = null;
  /**
   * The input calculates a `padding-right` by multipling the amount of letters on `action` by
   * the value defined on this property.
   * @type {Number}
   */
  @bindable letterWidth = defaultLetterWidth;
  /**
   * Whether or not the user can trigger the action without changing the value of the input.
   * @type {Boolean}
   */
  @bindable allowActionRepeat = true;
  /**
   * A callback that gets invoked when the value of the input changes.
   * @type {Function(text:String)}
   */
  @bindable onChange = () => {};
  /**
   * A callback that gets invoked when the user clicks on the action button.
   * @type {Function(text:String)}
   */
  @bindable onActionClick = () => {};
  /**
   * Every time the component calls `onActionClick`, it saves the value that was sent so if
   * `allowActionRepeat` is set to `true`, it won't send the same value twice in a row.
   * @type {?String}
   * @access protected
   * @ignore
   */
  _lastValueSent = null;
  /**
   * This is called every time the input value changes, and it just invokes the callback
   * informing the change.
   */
  saveInput() {
    this.onChange({ text: this.value });
  }
  /**
   * This is called when the user clicks on the action button, and it just invokes the callback.
   */
  triggerAction() {
    if (this.allowActionRepeat || this.value !== this._lastValueSent) {
      this._lastValueSent = this.value;
      this.onActionClick({ text: this.value });
    }
  }
  /**
   * The value for the input's `padding-right`.
   * @type {Number}
   */
  @computedFrom('action', 'letterWidth')
  get inputPaddingRight() {
    return this.action.length * this.letterWidth;
  }
  /**
   * Whether or not the input should be disabled.
   * @type {Boolean}
   */
  @computedFrom('loading', 'disabled')
  get isDisabled() {
    return this.loading || this.disabled;
  }
  /**
   * Whether or not the value of the input is the same that the last time the action button was
   * clicked.
   * @type {Boolean}
   */
  @computedFrom('value', '_lastValueSent')
  get sameValue() {
    return this.value === this._lastValueSent;
  }
  /**
   * Whether or not the action button is disabled.
   * @type {Boolean}
   */
  @computedFrom('value', 'allowActionRepeat', 'sameValue')
  get isActionDisabled() {
    return !this.value || (this.allowActionRepeat && this.sameValue);
  }
  /**
   * The message properties in case the component is being used for validations.
   * @type {InputWithActionMessage}
   */
  @computedFrom('loading', 'actionMessage', 'sameValue')
  get message() {
    let result = {
      show: false,
    };
    if (
      !this.loading &&
      this.sameValue &&
      this.actionMessage &&
      this.actionMessage.text &&
      typeof this.actionMessage.success !== 'undefined'
    ) {
      result = Object.assign({}, result, { show: true }, this.actionMessage);
    }

    return result;
  }
  /**
   * Whether or not the input and, if exists, the message should be both highlighted as if it
   * had an error.
   * @type {Boolean}
   */
  @computedFrom('error', 'message')
  get hasAnError() {
    return this.error || this.message.success === false;
  }
}

export { InputWithAction };
