import { bindable, computedFrom } from 'aurelia-framework';
import statuses from 'statuses';
/**
 * Handles the functionality of editing the settings of a customer account for an
 * specific feature.
 */
class FeatureContainer {
  /**
   * The basic information of the customer account.
   * @type {?AccountInformation}
   */
  @bindable selectedAccount = null;
  /**
   * The basic information of the feature.
   * @type {?Object}
   */
  @bindable selectedFeature = null;
  /**
   * Callback called when the customer account settings are saved.
   * @type {Function(settings:Object)}
   */
  @bindable onSave = () => {};
  /**
   * Whether or not the account information was successfully saved. This is also used by the
   * container to let the form know that the data was successfully saved and that it should
   * consider any further modification as a new change, since the form only enables the submit
   * button when there are changes.
   * @type {Boolean}
   */
  success = false;
  /**
   * This object will eventually be filled with the `selectedFeature` settings.
   * @type {Object}
   */
  featureSettings = {};
  /**
   * This object will eventually be filled with all the customer account information.
   * @type {Object}
   */
  account = {};
  /**
   * This dictionary will be used to store Salesforce IDs' validations. The format will be: The
   * ID as the key, and whatever {@link Salesforce} returns as the value. In case an ID is not
   * valid, the value will be just `false`.
   * @type {Object}
   */
  salesforceValidations = {};
  _loadingAccount = false;
  _loadingFeature = false;
  /**
   * @param {AppErrorPublisher} appErrorPublisher To handle possible errors when loading the
   *                                              feature and the customer account information.
   * @param {AppLogger}         appLogger         To log information messages in case some of the
   *                                              customer account settings can't be parsed.
   * @param {CustomerAccounts}  customerAccounts  To get the selected customer account information.
   * @param {errorParser}       errorParser       To parse errors.
   * @param {Features}          features          To get the selected feature settings.
   * @param {Router}            router            To navigate back to the user selection in the
   *                                              case the settings can't be loaded.
   * @param {Salesforce}        salesforce        To validate IDs.
   * @param {ViewMessages}      viewMessages      To confirm whether the user wants to deactivate
   *                                              a customer account or not.
   */
  constructor(
    appErrorPublisher,
    appLogger,
    customerAccounts,
    errorParser,
    features,
    router,
    salesforce,
    viewMessages,
  ) {
    'inject';

    /**
     * A local reference for the `appErrorPublisher` service.
     * @type {AppErrorPublisher}
     * @access protected
     * @ignore
     */
    this._appErrorPublisher = appErrorPublisher;
    /**
     * A local reference for the `appLogger` service.
     * @type {AppLogger}
     * @access protected
     * @ignore
     */
    this._appLogger = appLogger;
    /**
     * A local reference for the `customerAccounts` service.
     * @type {CustomerAccounts}
     * @access protected
     * @ignore
     */
    this._customerAccounts = customerAccounts;
    /**
     * A local reference for the `features` service.
     * @type {Features}
     * @access protected
     * @ignore
     */
    this._features = features;
    /**
     * The parser the container will use to format possible errors.
     * @type {ParserrorWrapper}
     * @access protected
     * @ignore
     */
    this._featuresErrorParser = errorParser.wrap();
    /**
     * A local reference for the `router` service.
     * @type {Router}
     * @access protected
     * @ignore
     */
    this._router = router;
    /**
     * A local reference for the `salesforce` service.
     * @type {Salesforce}
     * @access protected
     * @ignore
     */
    this._salesforce = salesforce;
    /**
     * A local reference for the `viewMessages` service.
     * @type {ViewMessages}
     * @access protected
     * @ignore
     */
    this._viewMessages = viewMessages;
  }
  /**
   * Whenever the selected feature changes, if the container is not already loading data, it
   * calls the method that loads the settings.
   */
  selectedFeatureChanged() {
    if (this.selectedAccount) {
      this._loadData();
    }
  }
  /**
   * Whenever the selected account changes, if the container is not already loading data, it
   * calls the method that loads the settings.
   */
  selectedAccountChanged() {
    if (!this.loading && this.selectedFeature) {
      this._loadData();
    }
  }
  /**
   * This is called from the form in case one of the customer account settings can't be parsed.
   * It logs an `info` message on the console.
   * @param {String} message The message sent by the form.
   */
  fieldDetectionWarning(message) {
    this._appLogger.info(message);
  }
  /**
   * This is called from the form when the user wants to validate a Salesforce ID. It uses the
   * {@link Salesforce} service to make the validation, and then stores the results on the
   * container.
   * @param {String} value The Salesforce ID to validate.
   */
  validateSalesforce(value) {
    this._salesforce.getAccount(value)
    .then((validation) => {
      this._addSalesforceValidation(value, validation);
    })
    .catch((error) => {
      if (error.status === statuses['not found']) {
        this._addSalesforceValidation(value, false);
      } else {
        this._appErrorPublisher.publish(error);
      }
    });
  }
  /**
   * Saves the customer account information.
   * @param {Object} changes The changes made to the customer account information. To be clear,
   *                         this parameter won't have all the information, but just the things
   *                         that changed.
   */
  saveAccount(changes) {
    this._loadingAccount = true;
    this.success = false;
    this._customerAccounts.updateCustomerAccount(
      this.selectedAccount.id,
      changes,
    )
    .catch((error) => {
      const parsedError = this._featuresErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    })
    .then(() => {
      this.success = true;
      return this._customerAccounts.getCustomerAccount(this.selectedAccount.id);
    })
    .then((settings) => {
      this._loadingAccount = false;
      this.account = settings;
      this.onSave({ settings });
    });
  }
  /**
   * Deactivates the selected customer account.
   */
  deactivateAccount() {
    const message = 'Are you sure you want to deactivate ' +
      `<strong>${this.selectedAccount.name}</strong>?`;
    this._viewMessages.confirm('deactivate', 'Confirmation', message)
    .then((response) => {
      let nextStep;
      if (response) {
        this._loadingAccount = true;
        nextStep = this._customerAccounts.deactivateCustomerAccount(this.selectedAccount.id);
      }

      return nextStep;
    })
    .then((account) => {
      if (account) {
        this.account = account;
      }
    })
    .catch((error) => {
      const parsedError = this._featuresErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    })
    .then(() => {
      this._loadingAccount = false;
    });
  }
  /**
   * Whether or not the container is loading information.
   * @type {Boolean}
   */
  @computedFrom('_loadingAccount', '_loadingFeature')
  get loading() {
    return this._loadingAccount || this._loadingFeature;
  }
  /**
   * Whether or not to show the button to deactivate a customer account.
   * @type {Boolean}
   */
  @computedFrom('selectedFeature', 'account')
  get showDeactivateButton() {
    return (
      this.account &&
      this.account.status !== 'INACTIVE' &&
      this.selectedFeature &&
      this.selectedFeature.id === this._features.basicFeatureId
    );
  }
  /**
   * Calls the methods that load the feature and customer account settings.
   * @access protected
   * @ignore
   */
  _loadData() {
    // Remove all previous validations if the feature or the account changed.
    this.salesforceValidations = {};
    Promise.all([
      this._loadAccountInformation(),
      this._loadFeatureSettings(),
    ])
    .catch((error) => {
      const parsedError = this._featuresErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);

      return this._router.navigateToRoute('accounts');
    });
  }
  /**
   * Loads the feature settings and saves them on the container.
   * @return {Promise<undefined,Error>}
   * @access protected
   * @ignore
   */
  _loadFeatureSettings() {
    let result;
    /**
     * This can be triggered because the account changed, so the method checks and if the feature
     * didn't change, it just returns an already resolved Promise.
     */
    if (this.featureSettings && this.featureSettings.id === this.selectedFeature.id) {
      result = Promise.resolve();
    } else {
      this._loadingFeature = true;
      result = this._features.getFeatureSettings(this.selectedFeature.id)
      .then((settings) => {
        this._loadingFeature = false;
        this.featureSettings = settings;
      })
      .catch((error) => {
        this._loadingFeature = false;
        return Promise.reject(error);
      });
    }

    return result;
  }
  /**
   * Loads the customer account information and saves it on the container.
   * @return {Promise<undefined,Error>}
   * @access protected
   * @ignore
   */
  _loadAccountInformation() {
    let result;
    /**
     * This can be triggered because the feature changed, so the method checks and if the account
     * didn't change, it just returns an already resolved Promise.
     */
    if (this.account && this.account.id === this.selectedAccount.id) {
      result = Promise.resolve();
    } else {
      this._loadingAccount = true;
      this.account = Object.assign({}, this.selectedAccount);
      result = this._customerAccounts.getCustomerAccount(this.selectedAccount.id)
      .then((settings) => {
        this.account = settings;
        this._loadingAccount = false;
      })
      .catch((error) => {
        this._loadingAccount = false;
        return Promise.reject(error);
      });
    }

    return result;
  }
  /**
   * Updates the validation results of a Salesforce account on the container dictionary.
   * @param {String} salesforceId The Salesforce account ID.
   * @param {*}      validation   The results of the validation.
   * @access protected
   * @ignore
   */
  _addSalesforceValidation(salesforceId, validation) {
    this.salesforceValidations = Object.assign(
      {},
      this.salesforceValidations,
      {
        [salesforceId]: validation,
      },
    );
  }
}

export { FeatureContainer };
