/* eslint-disable max-classes-per-file */
import EntitiesList from 'aurelio/src/abstracts/entitiesList';
/**
 * This class takes care of handling customer accounts' lists loaded from the API.
 * @extends {EntitiesList}
 */
export class CustomerAccountsList extends EntitiesList {
  /**
   * @param {AppAPI} appAPI To make the requests.
   */
  constructor(appAPI) {
    super();

    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     * @ignore
     */
    this._appAPI = appAPI;
    // Set the pagination type to `list` in order to save all the entities.
    this._setPaginationAsList();
  }
  /**
   * Loads the initial list of customer accounts.
   * @return {Promise<Array,Error>}
   */
  loadAccounts() {
    return this._loadFirstPage();
  }
  /**
   * Moves to the next page of customer accounts. If is not loaded, it makes a
   * request for it.
   * @return {Promise<Array,Error>}
   */
  moveToTheNextPage() {
    return this._moveTheListToTheNextPage();
  }
  /**
   * Moves to the previous page of customer accounts.
   * @return {Promise<Array,Error>}
   */
  moveToThePreviousPage() {
    return this._moveTheListToThePreviousPage();
  }
  /**
   * Makes the request to load the customer accounts from the API.
   * @return {Promise<Array,Error>}
   * @access protected
   * @ignore
   */
  _makeFirstPageRequest() {
    return this._getAccounts();
  }
  /**
   * Makes the request to load a new page of customer accounts from the API.
   * @return {Promise<Array,Error>}
   * @access protected
   * @ignore
   */
  _makeNextPageRequest() {
    return this._getAccounts();
  }
  /**
   * The actual method that loads customer accounts from the API. It uses the class
   * properties to know the page, so there's no need for multiple methods.
   * @return {Promise<Array,Error>}
   * @access protected
   * @ignore
   */
  _getAccounts() {
    return this._appAPI.getCustomerAccountsList(
      this.currentPage,
      this.searchQuery,
      this.entitiesPerPage,
    );
  }
  /**
   * Formats the API response to be compatible with {@link EntitiesList}.
   * @param {Object} response The API response.
   * @return {Object}
   * @property {Array}  entities The list of customer accounts.
   * @property {Number} total    The total amount of customer accounts there are.
   * @access protected
   * @ignore
   */
  _formatResponse(response) {
    return {
      entities: response.customer_accounts,
      total: response.pagination.total,
    };
  }
}
/**
 * A "factory class" that instantiates {@link CustomerAccountsList} on demand.
 */
export class CustomerAccountsListManager {
  /**
   * @param {AppAPI} appAPI To send to the new instances of {@link CustomerAccountsList}.
   */
  constructor(appAPI) {
    'inject';

    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     * @ignore
     */
    this._appAPI = appAPI;
  }
  /**
   * Gets a new instance of {@link CustomerAccountsList}.
   * @return {CustomerAccountsList}
   */
  newList() {
    return new CustomerAccountsList(this._appAPI);
  }
}
