import { computedFrom } from 'aurelia-framework';

/**
 * @typedef {Object} AccountsContainerRouteParams
 * @property {?Number} accountId The ID of the selected account.
 * @property {?String} feature   The name of the selected feature/action.
 */

/**
 * This is the main container for the accounts section. It renders the sidebar navigation
 * and the content.
 */
class AccountsContainer {
  /**
   * Whether or not the container is loading information.
   * @type {Boolean}
   */
  loading = false;
  /**
   * When the container is activated, this list will be filled with all the
   * customer accounts' features.
   * @type {Array}
   */
  featuresList = [];
  /**
   * The list of available actions for the customer accounts. Actions are different from features
   * as features are a set of settings the user can edit from an account, where actions are like
   * sub sections with extra information of the account.
   * @type {Array}
   */
  actionsList = [
    {
      id: 'activities',
      name: 'activities',
      title: 'Activities',
      listSelectorOptions: {
        badge: 'βeta',
      },
    },
  ];
  /**
   * The information of the selected account, if there's one.
   * @type {?AccountInformation}
   */
  selectedAccount = null;
  /**
   * The information of the selected feature, if there's one.
   * @type {?Object}
   */
  selectedFeature = null;
  /**
   * The information of the selected action, if there's one.
   * @type {?Object}
   */
  selectedAction = null;
  /**
   * Whether or not to show the modal with the form to create a new customer account.
   * @type {Boolean}
   */
  showNewAccountModal = false;
  /**
   * @param {AppErrorPublisher}      appErrorPublisher      To publish possible errors when
   *                                                        loading accounts information.
   * @param {errorParser}            errorParser            To parse errors.
   * @param {Features}               features               To get the list of customer accounts'
   *                                                        features.
   * @param {Router}                 router                 To make redirections when there's no
   *                                                        feature selected.
   * @param {SelectedAccountOptions} selectedAccountOptions To validate the account selection.
   */
  constructor(
    appErrorPublisher,
    errorParser,
    features,
    router,
    selectedAccountOptions,
  ) {
    'inject';

    /**
     * The parser the container will use to format possible errors.
     * @type {ParserrorWrapper}
     * @access protected
     * @ignore
     */
    this._accountsErrorParser = errorParser.wrap();
    /**
     * A local reference for the `appErrorPublisher` service.
     * @type {AppErrorPublisher}
     * @access protected
     * @ignore
     */
    this._appErrorPublisher = appErrorPublisher;
    /**
     * A local reference for the `features` service.
     * @type {Features}
     * @access protected
     * @ignore
     */
    this._features = features;
    /**
     * A local reference for the `router` service.
     * @type {Router}
     * @access protected
     * @ignore
     */
    this._router = router;
    /**
     * A local reference for the `selectedAccountOptions` service.
     * @type {SelectedAccountOptions}
     * @access protected
     * @ignore
     */
    this._selectedAccountOptions = selectedAccountOptions;
  }
  /**
   * This is called when the user navigates to the component. This method will just
   * fire up the method that validates the parameters and loads all the necessary
   * information for the container to work.
   * @param {AccountsContainerRouteParams} params The route parameters.
   * @param {Route}                        route  The current route information.
   */
  activate(params, route) {
    this._updateParams(route, params);
  }
  /**
   * Toggle the new account modal visibility.
   */
  toggleNewAccountModal() {
    this.showNewAccountModal = !this.showNewAccountModal;
  }
  /**
   * This is called after the user creates a new customer account and it just navigates the app
   * to the route to edit the newly created account.
   * @param {Object} account The account that was created.
   */
  loadNewAccount(account) {
    this._router.navigateToRoute('accounts', {
      accountId: account.id,
    });
  }
  /**
   * Called when the selected customer account settings are updated.
   * @param {Object} settings The current customer account settings.
   */
  updateAccountInformation(settings) {
    if (settings.name !== this.selectedAccount.name) {
      this.selectedAccount = Object.assign({}, this.selectedAccount, {
        name: settings.name,
      });
      this._selectedAccountOptions.select(this.selectedAccount);
    }
  }
  /**
   * A small object to make it easier for the view to know what should it show
   * on the `content` slot.
   * @type {Object}
   * @property {Boolean} showPlaceholder Whether or not to show the placeholder message informing
   *                                     the user that he/she needs to select an account first.
   * @property {Boolean} showFeature     Whether or not the container is ready to show the feature
   *                                     content.
   * @property {Object}  showAction      Whenever an action is selected, this object will have a
   *                                     property with that action `id` set to `true`, so the view
   *                                     can check if `showAction.[id]`.
   * @property {Boolean} showLoading     Whether or not to show the loading indicator.
   */
  @computedFrom('loading', 'selectedAccount', 'selectedFeature')
  get displayOptions() {
    const hasAccount = !!this.selectedAccount;
    const hasFeature = !!this.selectedFeature;
    const hasAction = !!this.selectedAction;

    const showAction = {};
    if (hasAction) {
      showAction[this.selectedAction.id] = true;
    }

    return {
      showPlaceholder: !this.loading && !hasAccount && !hasAction,
      showFeature: !this.loading && hasAccount && hasFeature,
      showAction,
      showLoading: this.loading,
    };
  }
  /**
   * It first loads the information about the customer accounts' features, then it
   * validates the route parameters in order to see if it's necessary to load
   * information for the API.
   * The reason the order of the parameters is not the same as on `activate` is so
   * we can make `params` optional and set a default value. This way, there's no need
   * to check if `params` was recevied, it's always there.
   * @param {Route}                        route       The current route information,
   *                                                   to update the route title with
   *                                                   an account name.
   * @param {AccountsContainerRouteParams} [params={}] The current route recevied
   *                                                   parameters.
   * @return {Promise<undefined,Error>}
   * @access protected
   * @ignore
   */
  _updateParams(route, params = {}) {
    this.loading = true;
    // Get the list of features.
    return this._features.getFeatures()
    .then((features) => {
      // Save the list of features.
      this.featuresList = features;
      let nextStep;
      if (params.accountId) {
        /**
         * If there's a selected account ID, cast it into a number and validate it.
         */
        const accountId = Number(params.accountId);
        if (Number.isNaN(accountId)) {
          // If the account is not a valid number, remove any possible selection.
          this._selectedAccountOptions.unselect();
          this.selectedAccount = null;
          this.selectedFeature = null;
          this.selectedAction = null;
        } else {
          // If it's a real number, call the service that validates it and gets the information.
          nextStep = this._selectedAccountOptions.validate(accountId);
        }
      } else {
        // If there wasn't a selected account ID, remove any possible selection.
        this._selectedAccountOptions.unselect();
        this.selectedAccount = null;
        this.selectedFeature = null;
        this.selectedAction = null;
      }

      return nextStep;
    })
    .then((account) => {
      /**
       * This variable will be send to the next `.then` in order to let it know that
       * the app should be redirected to the default feature.
       * The reasons in which this will change are:
       * - If an account was selected but with no feature/action (the normal behavior).
       * - If an account was selected and there's an unknown feature/action selected.
       */
      let fallbackRedirection = false;
      // If an account was loaded...
      if (account) {
        // ...save the account information.
        this.selectedAccount = account;
        // ...update the route title.
        route.navModel.setTitle(this._getRouteTitle(
          this.selectedAccount.name,
          route.title,
        ));

        const feature = this.featuresList.find(({ name }) => name === params.feature);
        if (feature) {
          this.selectedFeature = feature;
          this.selectedAction = null;
        } else {
          const action = this.actionsList.find(({ name }) => name === params.feature);
          if (action) {
            this.selectedAction = action;
            this.selectedFeature = null;
          } else {
            fallbackRedirection = true;
          }
        }
      }

      return fallbackRedirection;
    })
    .catch((error) => {
      const parsedError = this._accountsErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    })
    .then((fallbackRedirection) => {
      // If the redirection is needed, replace the current route.
      if (fallbackRedirection) {
        this._router.navigateToRoute(
          'accounts',
          {
            accountId: this.selectedAccount.id,
            feature: this._features.basicInformationFeature.name,
          },
          {
            replace: true,
          },
        );
      } else {
        this.loading = false;
      }
    });
  }
  /**
   * Generates the route title for an account name. It validates the length and
   * adds the title of the section.
   * @param {String} accountName The name of the selected account.
   * @param {String} routeTitle  The title of the current route.
   * @return {String}
   * @access protected
   * @ignore
   */
  _getRouteTitle(accountName, routeTitle) {
    const limit = 33;
    const limitWithoutDots = 30;
    const name = accountName.length > limit ?
      `${accountName.substr(0, limitWithoutDots)}...` :
      accountName;

    return `${name} | ${routeTitle}`;
  }
}

export { AccountsContainer };
