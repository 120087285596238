import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';

import { NAVIGATION } from './constants/navigation.constant';

const appComponent = PLATFORM.moduleName('./components/app/app.html');
/**
 * The configuration of the main plugin/module of the app.
 *
 * One of the most important things it does is taking care of registering alias for the default
 * services the app needs for the framework, that way, they can be injected using the `inject`
 * directive.
 * @param {Aurelia} aurelia The application information.
 */
export const configure = (aurelia) => {
  // Framework services
  aurelia.container.registerAlias(EventAggregator, 'eventAggregator');
  aurelia.container.registerAlias(HttpClient, 'httpClient');
  aurelia.container.registerAlias(Router, 'router');

  // Constants
  aurelia.container.registerInstance('NAVIGATION', NAVIGATION);

  // Components
  aurelia.globalResources(appComponent);
};
