// eslint-disable-next-line max-len
const salesforceHelp = 'https://photorank.atlassian.net/wiki/spaces/BI/pages/562856102/Assign+Salesforce+ID+to+a+Photorank+account';

export const BASIC_INFORMATION_FEATURE = {
  id: 'basic',
  name: 'basic',
  title: 'Basic information',
  description: 'Basic information',
  sections: [
    {
      name: 'basic',
      title: '',
      description: '',
      settings: [
        {
          id: 'b5021481-ba47-450a-8100-8a2f159403cc',
          name: 'basic.name',
          title: 'Name',
          type: 'input_text',
          visibility: 0,
          required: true,
          defaultValue: '',
        },
        {
          id: 'feaf10c6-e9de-4a3e-85b1-def68acbbdb5',
          name: 'basic.email',
          title: 'Email',
          type: 'input_text',
          visibility: 1,
          required: true,
          defaultValue: '',
          extras: {
            hideOnCreate: true,
          },
        },
        {
          id: 'c50de73a-78f1-496f-91e7-ddd8f2179134',
          name: 'basic.contact_email',
          title: 'Contact email(s)',
          type: 'input_text',
          visibility: 1,
          required: false,
          defaultValue: '',
          extras: {
            hideOnCreate: true,
          },
        },
        {
          id: '6a411c32-d896-44dd-8f8b-da7a32448d62',
          name: 'basic.user_dir',
          title: 'Templates directory',
          type: 'input_text',
          visibility: 1,
          required: true,
          defaultValue: '',
        },
        {
          id: '8f5f9490-a66b-4670-9520-4c60e3fa7b3b',
          name: 'basic.status',
          title: 'Status',
          type: 'select',
          options: 'DEMO:Demo, ACTIVE:Active, TEST:Test',
          visibility: 0,
          required: true,
          extras: {
            readOnlyOnExtraOptions: ['INACTIVE:Inactive'],
            hideOnCreate: true,
          },
          defaultValue: 'ACTIVE',
        },
        {
          id: '9abb2485-6db4-40aa-9a4c-20efdadc9bbf',
          name: 'basic.salesforce_id',
          title: 'Salesforce ID',
          type: 'input_salesforce',
          visibility: 0,
          required: false,
          defaultValue: '',
          extras: {
            help: salesforceHelp,
          },
        },
        {
          id: 'ac5202da-50dd-4112-9a3e-98128d672d1c',
          name: 'basic.salesforce_region',
          title: 'Market region',
          type: 'select',
          options: [
            '0:Select a region',
            '9:US',
            '5:N.AM',
            '4:Global',
            '1:APAC',
            '6:N/A',
            '2:CAN',
            '7:S.AM',
            '3:EMEA',
            '8:UK',
          ].join(','),
          visibility: 0,
          required: false,
          defaultValue: '0',
        },
        {
          id: '5dab7ccd-b863-4d49-a437-f20a3b7b143d',
          name: 'basic.salesforce_contract',
          title: 'Contract ID',
          type: 'input_salesforce',
          visibility: 0,
          required: false,
          extras: {
            help: salesforceHelp,
          },
        },
      ],
    },
  ],
};
