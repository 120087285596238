import Parserror from 'parserror';

const errorParser = Parserror
.new()
.addParser('directoryNameField', {
  template_dir: {
    label: 'Templates directory',
    field: 'user_dir',
  },
  user_dir: {
    label: 'Templates directory',
    field: 'user_dir',
  },
})
.addCases([
  /**
   * Catches when the Salesforce information is invalid.
   */
  {
    name: 'invalidSalesforceInformationOrId',
    condition: /Provided external ID field does not exist or is not accessible: .*?$/i,
    message: 'The Salesforce information is not valid or it wasn\'t found',
  },
  /**
   * Catches any other Salseforce error.
   */
  {
    name: 'unknownSalesforceError',
    condition: /Salesforce error: (.*?)$/i,
    message: (error) => error,
  },
  /**
   * Catches when the Template/User directory is not unique.
   */
  {
    name: 'nonUniqueNameField',
    condition: /(?:\w+\.)?(\w+) must be unique\.?/i,
    parse: ['directoryNameField'],
    message: (nameField) => {
      const useLabel = nameField.label || nameField;
      return `The ${useLabel} is already in use`;
    },
  },
  /**
   * Catches when the customer name's field is empty.
   */
  {
    name: 'notNullValue',
    condition: /^notNull Violation: (?:\w+\.)?(\w+) cannot be null/i,
    parse: ['directoryNameField'],
    message: (nameField) => {
      const useLabel = nameField.label || nameField;
      return `The field ${useLabel} is required`;
    },
  },
]);

export { errorParser };
