// eslint-disable-next-line no-process-env
const version = process.env.VERSION;

module.exports = {
  version,
  api: {
    url: `/api/${version}`,
    endpoints: {
      customerAccounts: {
        list: {
          path: 'customer-accounts/list/:page',
          query: {
            count: 20,
            search: null,
          },
        },
        extract: 'customer-accounts/:accountId/extract',
        byId: 'customer-accounts/:accountId',
        update: 'customer-accounts/:accountId/update',
        new: 'customer-accounts/new',
        deactivate: 'customer-accounts/:accountId',
        activities: 'activities/:accountId/:page',
      },
      features: {
        list: 'features/list',
        byId: 'features/:featureId',
      },
      salesforce: {
        getAccount: 'salesforce/:salesforceId',
      },
    },
  },
};
