/* eslint-disable max-classes-per-file */
import EntitiesList from 'aurelio/src/abstracts/entitiesList';

/**
 * This class takes care of handling activities' lists loaded from the API.
 * @extends {EntitiesList}
 */
export class ActivitiesList extends EntitiesList {
  /**
   * @param {Number} accountId The ID of the customer account.
   * @param {AppAPI} appAPI    To make the requests.
   */
  constructor(accountId, appAPI) {
    super();

    /**
     * The customer account ID.
     * @type {Number}
     * @access protected
     * @ignore
     */
    this._accountId = accountId;
    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     * @ignore
     */
    this._appAPI = appAPI;
    // Set the pagination type to `list` in order to save all the entities.
    this._setPaginationAsList();
  }
  /**
   * Loads the initial list of activities.
   * @return {Promise<Array,Error>}
   */
  loadActivities() {
    return this._loadFirstPage();
  }
  /**
   * Moves to the next page of activities. If is not loaded, it makes a request for
   * it.
   * @return {Promise<Array,Error>}
   */
  moveToTheNextPage() {
    return this._moveTheListToTheNextPage();
  }
  /**
   * Moves to the previous page of activities.
   * @return {Promise<Array,Error>}
   */
  moveToThePreviousPage() {
    return this._moveTheListToThePreviousPage();
  }
  /**
   * Makes the request to load the activities from the API.
   * @return {Promise<Array,Error>}
   * @access protected
   * @ignore
   */
  _makeFirstPageRequest() {
    return this._getActivities();
  }
  /**
   * Makes the request to load a new page of activities from the API.
   * @return {Promise<Array,Error>}
   * @access protected
   * @ignore
   */
  _makeNextPageRequest() {
    return this._getActivities();
  }
  /**
   * The actual method that loads activities from the API. It uses the class
   * properties to know the page, so there's no need for multiple methods.
   * @return {Promise<Array,Error>}
   * @access protected
   * @ignore
   */
  _getActivities() {
    return this._appAPI.getAccountActivities(
      this._accountId,
      this.currentPage,
      this.itemsPerPage,
    );
  }
  /**
   * Formats the API response to be compatible with {@link EntitiesList}.
   * @param {Object} response The API response.
   * @return {Object}
   * @property {Array}  entities The list of activities.
   * @property {Number} total    The total amount of activities there are.
   * @access protected
   * @ignore
   */
  _formatResponse(response) {
    return {
      entities: response.activities,
      total: response.count,
    };
  }
}
/**
 * A "factory class" that instantiates {@link ActivitiesList} on demand.
 */
export class ActivitiesListManager {
  /**
   * @param {AppAPI} appAPI To send to the new instances of {@link ActivitiesList}.
   */
  constructor(appAPI) {
    'inject';

    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     * @ignore
     */
    this._appAPI = appAPI;
  }
  /**
   * Gets a new instance of {@link ActivitiesList}.
   * @param {Number} accountId The ID of the customer account.
   * @return {ActivitiesList}
   */
  newList(accountId) {
    return new ActivitiesList(accountId, this._appAPI);
  }
}
