import { PLATFORM } from 'aurelia-pal';

const odsMenu = PLATFORM.moduleName('olapic-design-system/elements/menu/aurelia/odsMenu');
const odsPagination = PLATFORM
.moduleName('olapic-design-system/elements/pagination/aurelia/odsPagination');
const odsSearchBox = PLATFORM
.moduleName('olapic-design-system/elements/searchBox/aurelia/odsSearchBox');
const odsDropdown = PLATFORM
.moduleName('olapic-design-system/elements/dropdown/aurelia/odsDropdown');
const odsModal = PLATFORM
.moduleName('olapic-design-system/elements/modal/aurelia/odsModal');
/**
 * Registers all the Olapic Design System's components the app needs access to.
 * @param {Aurelia} aurelia The application information.
 */
export const configure = (aurelia) => {
  // Resources
  aurelia.globalResources(
    odsMenu,
    odsPagination,
    odsSearchBox,
    odsDropdown,
    odsModal,
  );
};
