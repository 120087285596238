import { PLATFORM } from 'aurelia-pal';

import { SESSION_EVENTS } from 'aurelio/src/constants/sessionEvents.constant';
import { ERROR_EVENTS } from 'aurelio/src/constants/errorEvents.constant';
import { ApiAuthorizationInterceptor } from 'aurelio/src/services/interceptors/apiAuthorizationInterceptor.service';
import { ApiVersionInterceptor } from 'aurelio/src/services/interceptors/apiVersionInterceptor.service';
import { AppConfiguration } from 'aurelio/src/services/appConfiguration.service';
import { AppErrorPublisher } from 'aurelio/src/services/appErrorPublisher.service';
import { AppLogger } from 'aurelio/src/services/appLogger.service';
import { AppObjectStorage } from 'aurelio/src/services/appObjectStorage.service';
import { AppRequestsCache } from 'aurelio/src/services/appRequestsCache.service';
import { AppRouting } from 'aurelio/src/services/appRouting.service';
import { AppSession } from 'aurelio/src/services/appSession.service';
import { AppSessionCapabilities } from 'aurelio/src/services/appSessionCapabilities.service';
import { AppSessionStore } from 'aurelio/src/services/appSessionStore.service';
import { AppUtils } from 'aurelio/src/services/appUtils.service';
import { ExternalLibraries } from 'aurelio/src/services/externalLibraries.service';
import { Intercom } from 'aurelio/src/services/intercom.service';
import { SentryPublisher } from 'aurelio/src/services/sentryPublisher.service';
import { AppRoutingStep } from 'aurelio/src/steps/appRouting.step';

const menuContainer = PLATFORM.moduleName('aurelio/containers/menu/menu.container');
/**
 * Registers all the OAL's services and containers the app needs access to.
 * @param {FrameworkConfiguration} frameworkConfig       The object the framework uses to
 *                                                       configure resources.
 * @param {Object}                 options               Custom options to configure the
 *                                                       plugin/module.
 * @param {Object}                 options.configuration The app settings the plugin will send to
 *                                                       the `appConfiguration` service.
 */
export const configure = (frameworkConfig, options) => {
  // Constants
  frameworkConfig.container.registerInstance('SESSION_EVENTS', SESSION_EVENTS);
  frameworkConfig.container.registerInstance('ERROR_EVENTS', ERROR_EVENTS);

  // Services
  frameworkConfig.container.registerSingleton('apiAuthorizationInterceptor', ApiAuthorizationInterceptor);
  frameworkConfig.container.registerSingleton('apiVersionInterceptor', ApiVersionInterceptor);
  frameworkConfig.container.registerSingleton('appConfiguration', AppConfiguration);
  frameworkConfig.container.registerSingleton('appErrorPublisher', AppErrorPublisher);
  frameworkConfig.container.registerSingleton('appLogger', AppLogger);
  frameworkConfig.container.registerSingleton('appObjectStorage', AppObjectStorage);
  frameworkConfig.container.registerSingleton('appRequestsCache', AppRequestsCache);
  frameworkConfig.container.registerSingleton('appRouting', AppRouting);
  frameworkConfig.container.registerSingleton('appSession', AppSession);
  frameworkConfig.container.registerSingleton('appSessionCapabilities', AppSessionCapabilities);
  frameworkConfig.container.registerSingleton('appSessionStore', AppSessionStore);
  frameworkConfig.container.registerSingleton('appUtils', AppUtils);
  frameworkConfig.container.registerSingleton('externalLibraries', ExternalLibraries);
  frameworkConfig.container.registerSingleton('intercom', Intercom);

  // Steps
  frameworkConfig.container.registerInstance('appRoutingStep', AppRoutingStep);

  // Resources
  frameworkConfig.globalResources(menuContainer);

  // Configuration setup
  const appConfiguration = frameworkConfig.container.get('appConfiguration');
  appConfiguration.setConfig(options.configuration);

  // Error handling
  // - Publisher
  frameworkConfig.container.get('appErrorPublisher').start();
  // - Sentry
  const sentryConfig = appConfiguration.get('features.sentry');
  if (sentryConfig.enabled) {
    frameworkConfig.container.registerSingleton('sentryPublisher', SentryPublisher);
    frameworkConfig.container.get('sentryPublisher')
    .addFilter(SentryPublisher.invalidCredentialsFilterStrategy)
    .addFilter(SentryPublisher.invalidVersionFilterStrategy)
    .addFilter(SentryPublisher.localFileFilterStrategy)
    .setMetadata({ version: appConfiguration.get('version') })
    .start(sentryConfig.apiKey, sentryConfig.projectId);
  }
};
