import { PLATFORM } from 'aurelia-pal';
import { ActivitiesListManager } from './services/activitiesList';
import { AppAPI } from './services/appAPI';
import { CustomerAccounts } from './services/customerAccounts';
import { CustomerAccountsListManager } from './services/customerAccountsList';
import { errorParser } from './services/errorParser';
import { Salesforce } from './services/salesforce';

const inputWithAction = PLATFORM
.moduleName('./components/inputWithAction/inputWithAction');
const listSelector = PLATFORM.moduleName('./components/listSelector/listSelector');
const selectionPlaceholder = PLATFORM
.moduleName('./components/selectionPlaceholder/selectionPlaceholder.html');
const sidebarSelector = PLATFORM
.moduleName('./components/sidebarSelector/sidebarSelector');
/**
 * The configuration of the `common` plugin/module.
 * @param {Aurelia} aurelia The application information.
 */
export const configure = (aurelia) => {
  // Services
  aurelia.container.registerSingleton(
    'activitiesListManager',
    ActivitiesListManager,
  );
  aurelia.container.registerSingleton('appAPI', AppAPI);
  aurelia.container.registerSingleton(
    'customerAccounts',
    CustomerAccounts,
  );
  aurelia.container.registerSingleton(
    'customerAccountsListManager',
    CustomerAccountsListManager,
  );
  aurelia.container.registerSingleton('salesforce', Salesforce);

  // Components
  aurelia.globalResources(
    inputWithAction,
    listSelector,
    selectionPlaceholder,
    sidebarSelector,
  );

  // Libraries
  aurelia.container.registerInstance('errorParser', errorParser);
};
