import { bindable, computedFrom } from 'aurelia-framework';
import { boundMethod } from 'autobind-decorator';
/**
 * @typedef {Object} ListSelectorItemsDisplayProperties
 * @property {String} labelWithDetail    The property that should be used a
 *                                       label when a detail is avaiable.
 * @property {String} labelWithoutDetail The property that should be used as
 *                                       label when there's no detail.
 * @property {String} detail             The property that should be used as
 *                                       detail.
 */

/**
 * @typedef {Object} ListSelectorItemDisplayProperties
 * @property {string}  route       The item route.
 * @property {string}  label       The label the view should show for the item.
 * @property {string}  detail      The detail the view should show for the item.
 * @property {?string} icon        The icon path the view could show for the item.
 * @property {?string} badge       The value the badge could show for the item.
 * @property {Boolean} isTitle     Whether or not the item is a title.
 * @property {Boolean} isItem      Whether or not the item is actually actionable.
 * @property {Boolean} selected    Whether or not the item is selected.
 * @property {Boolean} isSeparator Whether or not the item is a separator.
 */

/**
 * This component shows a list of items from which the user can:
 * - Select an item.
 * - Navigate backwards and forwards.
 * - Search an item.
 */
class ListSelector {
  /**
   * Whether or not to show a loading indicator.
   * @type {Boolean}
   */
  @bindable loading = false;
  /**
   * Whether or not to use buttons instead of links. When using buttons, the
   * implementation can manually redirect to the item route from the `onSelection`
   * callback.
   * @type {Boolean}
   */
  @bindable useButtons = false;
  /**
   * Whether or not to show the searchbox.
   * @type {Boolean}
   */
  @bindable showSearch = true;
  /**
   * Whether or not to use the items pagination.
   * @type {Boolean}
   */
  @bindable usePagination = true;
  /**
   * Whether or not to hide selected item from the list. This is in case the
   * implementation decides to show the selected item outside the list.
   * @type {Boolean}
   */
  @bindable hideSelected = false;
  /**
   * Whether or not to disable the items that are not selected. This only applies to
   * the main list of items.
   * @type {Boolean}
   */
  @bindable disableUnselected = false;
  /**
   * The text for the search box.
   * @type {String}
   */
  @bindable searchText = '';
  /**
   * The items to show.
   * @type {Array}
   */
  @bindable items = [];
  /**
   * The total amount of items.
   * @type {Number}
   */
  @bindable total = 0;
  /**
   * How many items per page should be visible.
   * @type {Number}
   */
  @bindable itemsPerPage = 0;
  /**
   * The pagination current page.
   * @type {Number}
   */
  @bindable itemsCurrentPage = 0;
  /**
   * The total amount of items pages there are.
   * @type {Number}
   */
  @bindable itemsTotalPages = 0;
  /**
   * The properties the component should use to show the item on the view.
   * @type {ListSelectorItemsDisplayProperties}
   */
  @bindable itemsDisplayProperties = {
    labelWithDetail: 'title',
    labelWithoutDetail: 'title',
    detail: 'description',
  };
  /**
   * The name of the route the component will use to generate links for the items.
   * @type {String}
   */
  @bindable itemsRouteName = '';
  /**
   * A dictionary of properties the component will use when generating the routes.
   * For example, if the route is `/items/:id` and that `id` should be the item
   * `itemId` property, this object would have `id: 'itemId'`.
   * @type {Object}
   */
  @bindable itemsRouteProperties = {};
  /**
   * The caption for the pagination.
   * @type {Object}
   * @property {Number} start Where the limit starts.
   * @property {Number} end   Where the limit ends.
   */
  @bindable itemsLimitCaption = { start: 0, end: 0 };
  /**
   * The limit for the items list.
   * @type {Object}
   * @property {Number} offset Where the limit begins.
   * @property {Number} limit  Where the limit ends.
   */
  @bindable itemsLimit = { offset: 0, limit: 0 };
  /**
   * The ID of the selected item.
   * @type {String|Number}
   */
  @bindable selectedItemId = '';
  /**
   * The name of the property that has the item ID.
   * @type {String}
   */
  @bindable itemIdProperty = 'id';
  /**
   * A list of static items to show above the regular items.
   * @type {Array}
   */
  @bindable staticItems = [];
  /**
   * Whether or not to put the static items on top or below the main list.
   * @type {Boolean}
   */
  @bindable staticItemsOnTop = true;
  /**
   * Callback for when the pagination needs to move to the next page.
   * @type {Function}
   */
  @bindable onMoveNext = () => {};
  /**
   * Callback for when the pagination needs to move to the previous page.
   * @type {Function}
   */
  @bindable onMoveBack = () => {};
  /**
   * Callback for when the search box text changes.
   * @type {Function(text:String)}
   */
  @bindable onSearchTextChange = () => {};
  /**
   * A callback for when the search box is attached to the DOM. It can be used to
   * obtain a reference for the search input.
   * @type {Function(info:Object)}
   */
  @bindable onSearchBoxAttached = () => {};
  /**
   * A callback for when the user selects an item.
   * @type {Function(item:Object,route:string,$event:Event)}
   */
  @bindable onSelection = () => {};
  /**
   * This is the real list of items the component shows. Whenever the `items` binding
   * changes, the component formats it and add _"special display properties"_, to
   * avoid adding functions and `if`s on the view.
   * @type {Array}
   */
  itemsList = [];
  /**
   * This is the real list of static items the component shows. Whenever the
   * `staticItems` binding changes, the component formats it and add _"special
   * dispay properties"_, to avoid adding functions and `if`s on the view.
   * @type {Array}
   */
  staticItemsList = [];
  /**
   * @param {Router} router To generate the items' routes.
   */
  constructor(router) {
    'inject';

    /**
     * A local reference for the `router` service.
     * @type {Router}
     * @access protected
     * @ignore
     */
    this._router = router;
  }
  /**
   * This is called every time the `items` list changes. The method takes care of
   * formatting the display properties the view needs to use and save them on the
   * `itemsList` property.
   * @param {Array} items The new list of items.
   */
  itemsChanged(items) {
    if (items && items.length) {
      this.itemsList = this._formatItemsList(items, true);
    } else {
      this.itemsList = [];
    }

    if (this.itemsLoaded !== this.itemsList.length) {
      this.itemsLoaded = this.itemsList.length;
    }
  }
  /**
   * This is called every time the `itemsDisplayProperties` object changes. If there's
   * already a formatted list of items, re format it, as it needs to use the new
   * display properties.
   */
  itemsDisplayPropertiesChanged() {
    if (this.itemsList.length) {
      this.itemsList = this._formatItemsList(this.itemsList, true, true);
    }

    if (this.staticItemsList.length) {
      this.staticItemsList = this._formatItemsList(this.staticItemsList, false, true);
    }
  }
  /**
   * This is called every time the `selectedItemId` changes. It loops the list of
   * items and updates the `selected` flag of their display properties.
   */
  selectedItemIdChanged() {
    if (this.items && this.items.length) {
      this.itemsList = this._updateItemsListSelection(this.itemsList, true);
    }

    if (this.staticItems && this.staticItems.length) {
      this.staticItemsList = this._updateItemsListSelection(
        this.staticItemsList,
        false,
      );
    }
  }
  /**
   * This is called every time the `staticItems` list changes. The method takes
   * care of formatting the display properties the view needs to use and save them
   * on the `staticItemsList` property.
   * @param {Array} items The new list of static items.
   */
  staticItemsChanged(items) {
    if (items && items.length) {
      this.staticItemsList = this._formatItemsList(items, false);
    } else {
      this.staticItemsList = [];
    }
  }
  /**
   * This is called from view on the items repeater, using the
   * {@link ListFilterValueConverter}. If `this.hideSelected` is set to `true`,
   * it filters out the selected item.
   * @type {Boolean}
   */
  @boundMethod
  hideSelectedItemIfNeeded(item) {
    return !this.hideSelected || !item.listSelectorDisplay.selected;
  }
  /**
   * This is called when an item is selected and it just invokes the callback to let
   * the implementation know about the selection.
   * @param {Object} item   The item information.
   * @param {Event}  $event The event triggered by the click.
   */
  selectItem(item, $event) {
    this._selectItem(
      item,
      this.items.find((original) => (
        original[this.itemIdProperty] === item[this.itemIdProperty]
      )),
      $event,
    );
  }
  /**
   * This is called when an static item is selected and it just invokes the
   * callback to let the implementation know about the selection.
   * @param {Object} item   The item information.
   * @param {Event}  $event The event triggered by the click.
   */
  selectStaticItem(item, $event) {
    this._selectItem(
      item,
      this.staticItems.find((original) => (
        original[this.itemIdProperty] === item[this.itemIdProperty]
      )),
      $event,
    );
  }
  /**
   * Whether or not to show the list of items, with pagination.
   * @type {Boolean}
   */
  @computedFrom('loading', 'usePagination')
  get showPaginatedList() {
    return !this.loading && this.usePagination;
  }
  /**
   * Whether or not to show the list of items, without pagination.
   * @type {Boolean}
   */
  @computedFrom('loading', 'usePagination')
  get showUnpaginatedList() {
    return !this.loading && !this.usePagination;
  }
  /**
   * Whether or not to show the list of static items on top of the main list.
   * @type {Boolean}
   */
  @computedFrom('staticItemsList', 'staticItemsOnTop')
  get showStaticItemsAboveTheMainList() {
    return this.staticItemsList.length > 0 && this.staticItemsOnTop;
  }
  /**
   * Whether or not to show the list of static items below the main list.
   * @type {Boolean}
   */
  @computedFrom('staticItemsList', 'staticItemsOnTop')
  get showStaticItemsBelowTheMainList() {
    return this.staticItemsList.length > 0 && !this.staticItemsOnTop;
  }
  /**
   * This is the real method that handles items selection and it's called when both
   * items and static items are selected.
   * It first removes the `focus` from the link/button that triggered the selection
   * as the UI doesn't refresh it and then invokes the callback.
   * @param {Object} item     The selected item.
   * @param {Object} original The original item, without the
   *                          _"special display properties"_.
   * @param  {Event} $event   The event triggered by the click.
   * @access protected
   * @ignore
   */
  _selectItem(item, original, $event) {
    $event.target.blur();
    this.onSelection({
      item: original,
      route: item.listSelectorDisplay.route,
      $event,
    });
  }
  /**
   * Generates a new list of items by formatting each of them and adding the
   * _"special display properties"_ the view will need.
   * @param {Array}   items         The original list.
   * @param {Boolean} main          Whether the formatting is form the main list or
   *                                the static one.
   * @param {Boolean} [force=false] In case the method needs to update the items that
   *                                already have _"special display properties"_. This
   *                                is in case the display properties changed.
   * @return {Array}
   * @access protected
   * @ignore
   */
  _formatItemsList(items, main, force = false) {
    let routePropNames;
    if (this.itemsRouteName) {
      routePropNames = Object.keys(this.itemsRouteProperties);
    }
    return items.map((item) => {
      let newItem;
      if (force || !item.listSelectorDisplay) {
        newItem = Object.assign({}, item, {
          listSelectorDisplay: this._getItemDisplayProperties(item, routePropNames),
        });
      } else {
        newItem = item;
      }

      return this._updateItemSelection(newItem, main);
    });
  }
  /**
   * Updates the `selected` property of a formatted list of items.
   * @param {Array}   items The list of items.
   * @param {Boolean} main  Whether this is for the main list or the static one.
   * @return {Array}
   * @access protected
   * @ignore
   */
  _updateItemsListSelection(items, main) {
    return items.map((item) => this._updateItemSelection(item, main));
  }
  /**
   * Updates the `selected` property of a formatted item.
   * @param {Object}  item The item where the property will be updated.
   * @param {Boolean} main Whether the item is on the main list or the static one.
   * @return {Object}
   * @access protected
   * @ignore
   */
  _updateItemSelection(item, main) {
    if (item.listSelectorDisplay) {
      const isSelected = item[this.itemIdProperty] === this.selectedItemId;
      if (item.listSelectorDisplay.selected && !isSelected) {
        item.listSelectorDisplay.selected = false;
      } else if (!item.listSelectorDisplay.selected && isSelected) {
        item.listSelectorDisplay.selected = true;
      }

      if (main) {
        item.listSelectorDisplay.disabled = this.disableUnselected && !isSelected;
      }
    }

    return item;
  }
  /**
   * This helper method creates the display properties for an item.
   * @param {Object} item           The item for which the properties will be
   *                                created for.
   * @param {Array}  routePropNames The names of the route properties that need to
   *                                be extracted from the item. The reason the
   *                                method receives them as an array is so the
   *                                parent method only does `Object.keys` once
   *                                instead of doing it for all the calls.
   * @return {ListSelectorItemDisplayProperties}
   * @access protected
   * @ignore
   */
  _getItemDisplayProperties(item, routePropNames) {
    let label;
    let detail;
    let badge;
    let hideDetail = false;
    let icon;
    let isTitle = false;
    let isSeparator = false;
    let isItem = true;
    if (item.listSelectorOptions) {
      hideDetail = !!item.listSelectorOptions.hideDetail;
      ({ icon, badge } = item.listSelectorOptions);
      isSeparator = item.listSelectorOptions.isSeparator === true;
      isTitle = item.listSelectorOptions.isTitle === true && !isSeparator;
      isItem = !isTitle && !isSeparator;
    }

    if (!hideDetail && item[this.itemsDisplayProperties.detail]) {
      label = item[this.itemsDisplayProperties.labelWithDetail];
      detail = item[this.itemsDisplayProperties.detail];
    } else {
      label = item[this.itemsDisplayProperties.labelWithoutDetail];
      detail = '';
    }

    let route;
    if (item.route) {
      ({ route } = item);
    } else if (routePropNames) {
      const routeParams = routePropNames.reduce((acc, key) => Object.assign(acc, {
        [key]: item[this.itemsRouteProperties[key]],
      }), {});
      route = this._router.generate(this.itemsRouteName, routeParams);
    }

    return {
      selected: false,
      icon,
      route,
      label,
      badge,
      detail,
      isTitle,
      isItem,
      isSeparator,
    };
  }
}

export { ListSelector };
