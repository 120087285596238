import { PLATFORM } from 'aurelia-pal';

import { BASIC_INFORMATION_FEATURE } from './constants/basicInformationFeature.constant';

import { Features } from './services/features';
import { SelectedAccountOptions } from './services/selectedAccountOptions';

const accountsNavigationContainer = PLATFORM
.moduleName('./containers/accountsNavigation/accountsNavigation.container');
const activitiesContainer = PLATFORM
.moduleName('./containers/activities/activities.container');
const featureContainer = PLATFORM
.moduleName('./containers/feature/feature.container');
const newAccountModalContainer = PLATFORM
.moduleName('./containers/newAccountModal/newAccountModal.container');

const accountsSection = PLATFORM
.moduleName('./components/accountsSection/accountsSection.html');
const accountsSectionHeader = PLATFORM
.moduleName('./components/accountsSectionHeader/accountsSectionHeader.html');
const activitiesList = PLATFORM
.moduleName('./components/activitiesList/activitiesList');
const featureForm = PLATFORM.moduleName('./components/featureForm/featureForm');

/**
 * The configuration of the `accounts` plugin/module.
 * @param {Aurelia} aurelia The application information.
 */
export const configure = (aurelia) => {
  // Constants
  aurelia.container.registerInstance(
    'BASIC_INFORMATION_FEATURE',
    BASIC_INFORMATION_FEATURE,
  );

  // Services
  aurelia.container.registerSingleton('features', Features);
  aurelia.container.registerSingleton(
    'selectedAccountOptions',
    SelectedAccountOptions,
  );

  // Resources
  aurelia.globalResources(
    accountsNavigationContainer,
    activitiesContainer,
    featureContainer,
    newAccountModalContainer,
    accountsSection,
    accountsSectionHeader,
    activitiesList,
    featureForm,
  );
};
