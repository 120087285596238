import { PLATFORM } from 'aurelia-pal';
import baseConfiguration from '../../config/browser.config';

const appContainer = PLATFORM.moduleName('modules/app/containers/app/app.container');

export const configure = (aurelia) => {
  aurelia.use
  .plugin(PLATFORM.moduleName('modules/app'))
  .plugin(PLATFORM.moduleName('aurelio'))
  .plugin(PLATFORM.moduleName('modules/aurelioSetup'), {
    configuration: Object.assign(
      {},
      baseConfiguration,
      PLATFORM.global.appConfiguration,
    ),
  })
  .plugin(PLATFORM.moduleName('aurelio/modules/viewMessages'))
  .plugin(PLATFORM.moduleName('modules/ods'))
  .plugin(PLATFORM.moduleName('modules/common'))
  .plugin(PLATFORM.moduleName('modules/accounts'))
  .standardConfiguration();

  return aurelia.start().then(() => aurelia.setRoot(appContainer));
};
