import { bindable } from 'aurelia-framework';
/**
 * This is the container in charge of requesting and showing a customer account activities.
 */
class ActivitiesContainer {
  /**
   * The information of the customer account.
   * @type {?Object}
   */
  @bindable selectedAccount = null;
  /**
   * The list of activities. This is created every time `selectedAccount` changes.
   * @type {?ActivitiesList}
   */
  list = null;
  /**
   * @param {ActivitiesListManager} activitiesListManager To get the lists objects.
   * @param {AppErrorPublisher}     appErrorPublisher     To publish possible errors when
   *                                                      loading accounts information.
   * @param {errorParser}           errorParser           To parse errors.
   */
  constructor(
    activitiesListManager,
    appErrorPublisher,
    errorParser,
  ) {
    'inject';

    /**
     * A local reference for the `activitiesListManager` service.
     * @type {ActivitiesListManager}
     * @access protected
     * @ignore
     */
    this._activitiesListManager = activitiesListManager;
    /**
     * A local reference for the `appErrorPublisher` service.
     * @type {AppErrorPublisher}
     * @access protected
     * @ignore
     */
    this._appErrorPublisher = appErrorPublisher;
    /**
     * The parser the container will use to format possible errors.
     * @type {ParserrorWrapper}
     * @access protected
     * @ignore
     */
    this._activitiesErrorParser = errorParser.wrap();
  }
  /**
   * Every time the selected account changes, this method will create and load a new list of
   * activities.
   */
  selectedAccountChanged() {
    this.list = this._activitiesListManager.newList(this.selectedAccount.id);
    this.list.loadActivities()
    .catch((error) => {
      const parsedError = this._activitiesErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    });
  }
  /**
   * Moves the list to the next page of activities.
   */
  moveToTheNextPage() {
    this.list.moveToTheNextPage()
    .catch((error) => {
      const parsedError = this._activitiesErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    });
  }
  /**
   * Moves the list to the previous page of activities.
   */
  moveToThePreviousPage() {
    this.list.moveToThePreviousPage()
    .catch((error) => {
      const parsedError = this._activitiesErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    });
  }
}

export { ActivitiesContainer };
