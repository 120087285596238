/**
 * A service to work with all customer account related information.
 */
class CustomerAccounts {
  /**
   * @param {AppAPI}                      appAPI                      To make requests to get
   *                                                                  information.
   * @param {CustomerAccountsListManager} customerAccountsListManager To create customer accounts'
   *                                                                  lists.
   */
  constructor(appAPI, customerAccountsListManager) {
    'inject';

    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     * @ignore
     */
    this._appAPI = appAPI;
    /**
     * A local reference for the `customerAccountsListManager` service.
     * @type {CustomerAccountsListManager}
     * @access protected
     * @ignore
     */
    this._customerAccountsListManager = customerAccountsListManager;
  }
  /**
   * Creates a new customer accounts' list.
   * @return {CustomerAccountsList}
   */
  newList() {
    return this._customerAccountsListManager.newList();
  }
  /**
   * Gets a customer account _"extract"_ by its ID. The extract is just the customer name and ID.
   * This is usually used on lists where you don't need the entire entity.
   * @param {Number} accountId The customer account ID.
   * @return {Promise<Object,Error>}
   */
  getCustomerAccountExtract(accountId) {
    return this._appAPI.getCustomerAccountExtract(accountId);
  }
  /**
   * Gets all the settings for a customer using its ID.
   * @param {Number} accountId The customer account ID.
   * @return {Promise<Object,Error}
   */
  getCustomerAccount(accountId) {
    return this._appAPI.getCustomerAccount(accountId);
  }
  /**
   * Creates a new customer account.
   * @param {Object} account The account information.
   * @return {Promise<Object,Error}
   */
  createCustomerAccount(account) {
    return this._appAPI.createCustomerAccount(account);
  }
  /**
   * Updates a customer account information.
   * @param {Number} accountId The customer account ID.
   * @param {Object} account   The account information.
   * @return {Promise<Object,Error}
   */
  updateCustomerAccount(accountId, settings) {
    return this._appAPI.updateCustomerAccount(accountId, settings);
  }
  /**
   * Deactivatesa a customer account.
   * @param {Number} accountId The customer account ID.
   * @return {Promise<Object,Error}
   */
  deactivateCustomerAccount(accountId) {
    return this._appAPI.deactivateCustomerAccount(accountId);
  }
}

export { CustomerAccounts };
