import { bindable, computedFrom } from 'aurelia-framework';
/**
 * This container manages all the sidebar navigation of the accounts module.
 */
class AccountsNavigationContainer {
  /**
   * Whether or not there's information being loaded.
   * @type {Boolean}
   */
  @bindable loading = false;
  /**
   * The information of the selected customer account.
   * @type {?AccountInformation}
   */
  @bindable selectedAccount = null;
  /**
   * The information of the selected customer account feature.
   * @type {?Object}
   */
  @bindable selectedFeature = null;
  /**
   * The list of available customer account features.
   * @type {Array}
   */
  @bindable features = [];
  /**
   * The information of the selected customer account action.
   * @type {?Object}
   */
  @bindable selectedAction = null;
  /**
   * The list of available customer account actions.
   * @type {Array}
   */
  @bindable actions = [];
  /**
   * A callback to inform the implementation that the user wants to create a new customer
   * account.
   * @type {Function}
   */
  @bindable onNewAccountRequest = () => {};
  /**
   * The value of the search text to filter customer accounts.
   * @type {String}
   */
  searchQuery = '';
  /**
   * The display properties for the accounts list selector.
   * @type {ListSelectorItemDisplayProperties}
   */
  accountsDisplayProperties = {
    labelWithDetail: 'name',
    labelWithoutDetail: 'name',
    detail: 'id',
  };
  /**
   * The display properties for the forms list selector.
   * @type {ListSelectorItemDisplayProperties}
   */
  featuresDisplayProperties = {
    labelWithoutDetail: 'title',
    detail: 'detail',
  };
  /**
   * The name of the route the accounts list selector should use to generate the
   * items' routes.
   * @type {String}
   */
  accountsRouteName = 'accounts';
  /**
   * The properties the accounts list selector should use to generate the routes.
   * @type {Object}
   */
  accountsRouteProperties = {
    accountId: 'id',
  };
  /**
   * Whether or not to trigger the request for the main link of customer accounts.
   * If the list is created for the first time, this will be `true`; but if it's
   * restored from the storage service, it will be `false`, as it already has data.
   * @type {Boolean}
   * @access protected
   * @ignore
   */
  _shouldLoadMainList = false;
  /**
   * The reference for the list selector search box input. This will be used to
   * restore focus after making a search request.
   * @type {?Element}
   * @access protected
   * @ignore
   */
  _searchBoxInput = null;
  /**
   * Whether or not to show the list, even if there's an account/option selected.
   * @type {Boolean}
   * @access protected
   * @ignore
   */
  _forceList = false;
  /**
   * @param {AppErrorPublisher}      appErrorPublisher      To publish possible errors from the
   *                                                        lists requests.
   * @param {errorParser}            errorParser            To parse errors.
   * @param {AppObjectStorage}       appObjectStorage       To save the main list and avoid making
   *                                                        multiple requests when the browser
   *                                                        hasn't refreshed the page.
   * @param {CustomerAccounts}       customerAccounts       To generate the main list and the
   *                                                        search list.
   * @param {Router}                 router                 To generate the route for the new
   *                                                        customer account form.
   * @param {SelectedAccountOptions} selectedAccountOptions To select the accounts and get the ID
   *                                                        value for creating new accounts.
   */
  constructor(
    appErrorPublisher,
    errorParser,
    appObjectStorage,
    customerAccounts,
    router,
    selectedAccountOptions,
  ) {
    'inject';

    /**
     * The main list of customer accounts.
     * @type {CustomerAccountsList}
     * @access protected
     * @ignore
     */
    this._mainList = appObjectStorage.save(
      'accounts',
      'navigationMainList',
      () => {
        this._shouldLoadMainList = true;
        return customerAccounts.newList();
      },
    );
    /**
     * The list used for the search results.
     * @type {CustomerAccountsList}
     * @access protected
     * @ignore
     */
    this._searchList = customerAccounts.newList();
    /**
     * The parser the container will use to format possible errors.
     * @type {ParserrorWrapper}
     * @access protected
     * @ignore
     */
    this._accountsNavigationErrorParser = errorParser.wrap();
    /**
     * A local reference for the `appErrorPublisher` service.
     * @type {AppErrorPublisher}
     */
    this._appErrorPublisher = appErrorPublisher;
    /**
     * A local reference for the `router` service.
     * @type {Router}
     */
    this._router = router;
    /**
     * A local reference for the `selectedAccountOptions` service.
     * @type {SelectedAccountOptions}
     */
    this._selectedAccountOptions = selectedAccountOptions;
    /**
     * This is a static item for the list selector with the link to create a new
     * customer account.
     * @type {Object}
     */
    this.newAccountItem = {
      name: 'Create a new customer account',
      id: 'new',
      route: 'new',
      listSelectorOptions: {
        hideDetail: true,
        icon: 'caret-plus',
      },
      selected: false,
    };
    /**
     * This is a static item for the forms list selectors. It's used to go back to the
     * list.
     * The idea of saving it as a property and not only on the list of statics (`formStaticItems`)
     * it's so the method that receives the selection can compare the `id` of the
     * _"selected feature"_ with the `id` of this object without having to destruct
     * the array and _"assume"_ this is the first item.
     * @type {Object}
     * @access protected
     * @ignore
     */
    this._backToListItem = {
      title: 'Back to the customer accounts list',
      id: 'backToTheList',
      route: this._router.generate('accounts'),
      listSelectorOptions: {
        icon: 'caret-left',
      },
    };
    /**
     * This is the list of static items sent to the forms list selectors.
     * @type {Array}
     */
    this.formStaticItems = [this._backToListItem];
  }
  /**
   * When the component gets attached to the DOM, checks if it should load the main list and does
   * it if needed.
   */
  attached() {
    if (this._shouldLoadMainList) {
      this._loadMainList();
    }
  }
  /**
   * This is called when the list selector search box field changes value. If there's a search
   * query, it triggers the request and restores the focus to the field.
   * @param {String} text The new search query.
   */
  search(text) {
    if (text !== this.searchQuery) {
      this.searchQuery = text;
      if (this.searchQuery) {
        const list = this.accountsList;

        list
        .reset()
        .search(this.searchQuery)
        .loadAccounts()
        .then(() => {
          if (this._searchBoxInput) {
            this._searchBoxInput.disabled = false;
            this._searchBoxInput.focus();
          }
        })
        .catch((error) => {
          const parsedError = this._accountsNavigationErrorParser(error);
          this._appErrorPublisher.notify(error, parsedError.message);
        });
      }
    }
  }
  /**
   * This is called when the list selector pagination wants to move to the next page. It calls
   * the _"active list"_ method for it.
   */
  moveToTheNextPage() {
    this.accountsList.moveToTheNextPage()
    .catch((error) => {
      const parsedError = this._accountsNavigationErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    });
  }
  /**
   * This is called when the list selector pagination wants to move to the previous page. It calls
   * the _"active list"_ method for it.
   */
  moveToThePreviousPage() {
    this.accountsList.moveToThePreviousPage()
    .catch((error) => {
      const parsedError = this._accountsNavigationErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    });
  }
  /**
   * This is called when the list selector search box is mounted on the DOM. The method takes
   * care of saving a reference for the search field so the focus can be restored after searchs.
   * @param {Object} ref       A dictionary with refrences for the search box component.
   * @param {Object} ref.input The refrence for the search field.
   */
  saveSearchBoxRef(ref) {
    this._searchBoxInput = ref.input;
  }
  /**
   * This is called when the user selects an option/account from the list selector. If `account`
   * is the option for creating a new account, it will trigger the modal; if it's an actual
   * account and different from the one currently selected, it will navegate to its route.
   * @param {Object} account The selected option/account information.
   */
  selectAccount(account, route) {
    if (account.id === this.newAccountItem.id) {
      this.onNewAccountRequest();
    } else if (
      !this.selectedAccount ||
      this.selectedAccount.id !== account.id
    ) {
      this._selectedAccountOptions.select(account);
      this._forceList = false;
      this._router.navigate(route);
    } else if (this._forceList) {
      this._forceList = false;
    }
  }
  /**
   * This is called when the user selects an option from one of the forms list
   * selector.
   * @param {Object} feature The selected feature/action/option information.
   */
  selectFeature(feature) {
    if (feature.id === this._backToListItem.id) {
      this._forceList = true;
    } else if (
      /**
       * If the _"feature"_ is indeed a feature and, there's no feature selected or the selected
       * one is different.
       */
      (
        feature.feature &&
        (!this.selectedFeature || this.selectedFeature.id !== feature.id)
      ) ||
      /**
       * If the _"feature"_ is an action and, there's no action selected or the selected one is
       * different.
       */
      (
        feature.action &&
        (!this.selectedAction || this.selectedAction.id !== feature.id)
      )
    ) {
      this._router.navigateToRoute(this.accountsRouteName, {
        accountId: this.selectedAccountId,
        feature: feature.name,
      });
    }
  }
  /**
   * Returns the _"active list"_: When there's a search query, it returns the search list,
   * otherwise, it returns the main list.
   * @type {CustomerAccountsList}
   */
  @computedFrom('searchQuery')
  get accountsList() {
    return this.searchQuery ?
      this._searchList :
      this._mainList;
  }
  /**
   * Returns the list of static items for the list selector. The getter also updates their
   * `selected` property in case they are indeed selected.
   * @type {Array}
   */
  @computedFrom('selectedAccount')
  get accountsStaticItems() {
    const items = [];
    if (this.selectedAccount) {
      items.push(Object.assign({ selected: true }, this.selectedAccount));
    }
    items.push(this.newAccountItem);
    return items;
  }
  /**
   * Gets the ID of the selected account, if there's one. It can be a number or a string because
   * the option to create a new customer account it's also handled as a _"selected account"_.
   * @type {?Number|?String}
   */
  @computedFrom('selectedAccount')
  get selectedAccountId() {
    return this.selectedAccount ? this.selectedAccount.id : null;
  }
  /**
   * Gets the ID of the selected feature/action.
   * @type {String}
   */
  @computedFrom('selectedFeature', 'selectedAction')
  get selectedFeatureId() {
    let result;
    if (this.selectedFeature) {
      result = this.selectedFeature.id;
    } else if (this.selectedAction) {
      result = this.selectedAction.id;
    } else {
      result = null;
    }

    return result;
  }
  /**
   * Whether or not to show the features list.
   * @type {Boolean}
   */
  @computedFrom('selectedAccountId', '_forceList')
  get showFeatures() {
    return this._forceList ? false : !!this.selectedAccountId;
  }
  /**
   * The list of features and actions for the presentational component. The list also includes
   * the _"title item"_ that separates features from actions.
   * @type {Array}
   */
  @computedFrom('features', 'actions')
  get featuresList() {
    return [
      ...this.features.map((feature) => Object.assign({ feature: true }, feature)),
      {
        name: 'separator',
        listSelectorOptions: {
          isSeparator: true,
        },
      },
      ...this.actions.map((action) => Object.assign({ action: true }, action)),
    ];
  }
  /**
   * Triggers the request to load the main list customer accounts. This is called from
   * `attached` if the `_shouldLoadMainList` is set to `true`.
   * @access protected
   * @ignore
   */
  _loadMainList() {
    this._mainList.loadAccounts()
    .catch((error) => {
      const parsedError = this._accountsNavigationErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    });
  }
}

export { AccountsNavigationContainer };
