import { bindable, computedFrom } from 'aurelia-framework';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
/**
 * Shows a list of activities for a customer account.
 */
class ActivitiesList {
  /**
   * Whether or not to show the loading indicator.
   * @type {Boolean}
   */
  @bindable loading = false;
  /**
   * The customer account information.
   * @type {?Object}
   */
  @bindable account = null;
  /**
   * The list of activities.
   * @type {Array}
   */
  @bindable activities = [];
  /**
   * The total amount of activities for the customer account.
   * @type {Number}
   */
  @bindable total = 0;
  /**
   * How many activities will be shown per page.
   * @type {Number}
   */
  @bindable activitiesPerPage = 0;
  /**
   * The current activities page.
   * @type {Number}
   */
  @bindable activitiesCurrentPage = 0;
  /**
   * The total amount of pages.
   * @type {Number}
   */
  @bindable activitiesTotalPages = 0;
  /**
   * The caption for the pagination.
   * @type {Object}
   * @property {Number} start Where the limit starts.
   * @property {Number} end   Where the limit ends.
   */
  @bindable activitiesLimitCaption = { start: 0, end: 0 };
  /**
   * The limit for the activities list.
   * @type {Object}
   * @property {Number} offset Where the limit begins.
   * @property {Number} limit  Where the limit ends.
   */
  @bindable activitiesLimit = { offset: 0, limit: 0 };
  /**
   * The callback to move the pagination to the previous page.
   * @return {Function}
   */
  @bindable onMoveBack = () => {};
  /**
   * The callback to move the pagination to the next page.
   * @return {Function}
   */
  @bindable onMoveNext = () => {};
  /**
   * This is called by the view in order to transform the list of activities and group them by
   * day. It will create a new list, this time of days, and inside each day, you'll have the
   * activities for it.
   * @param {Array} activities The list of activities to transform.
   * @return {Array}
   */
  activitiesByDay(activities) {
    const byDay = {};
    activities.forEach((activity) => {
      const published = parseISO(activity.published);
      const day = format(published, 'MMM do yyyy');
      if (!byDay[day]) {
        byDay[day] = {
          title: day,
          activities: [],
        };
      }

      byDay[day].activities.push(activity);
    });

    return Object.keys(byDay).map((day) => byDay[day]);
  }
  /**
   * Whether or not the pagination can move to the previous page.
   * @type {Boolean}
   */
  @computedFrom('loading', 'activitiesCurrentPage')
  get hasAPreviousPage() {
    return !this.loading && this.activitiesCurrentPage !== 1;
  }
  /**
   * Whether or not the pagination can move to the next page.
   * @type {Boolean}
   */
  @computedFrom('loading', 'activitiesCurrentPage', 'activitiesTotalPages')
  get hasANextPage() {
    return !this.loading && this.activitiesCurrentPage !== this.activitiesTotalPages;
  }
}

export { ActivitiesList };
