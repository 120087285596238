/**
 * A service to work with all customer account related information.
 * This service handles all information related to Salesforce.
 */
class Salesforce {
  /**
   * @param {AppAPI} appAPI To make requests to get information.
   */
  constructor(appAPI) {
    'inject';

    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     * @ignore
     */
    this._appAPI = appAPI;
  }
  /**
   * Gets the information of a Salesforce account by its ID.
   * @param {String} salesforceId The account ID.
   * @return {Promise<Object,undefined>}
   */
  getAccount(salesforceId) {
    return this._appAPI.getSalesforceAccount(salesforceId);
  }
}

export { Salesforce };
