import { bindable, computedFrom } from 'aurelia-framework';
import ObjectUtils from 'wootils/shared/objectUtils';
import statuses from 'statuses';
/**
 * This is the container for the modal form the users will use to create new customer accounts.
 */
class NewAccountModalContainer {
  /**
   * Whether or not the modal is open.
   * @type {Boolean}
   */
  @bindable open = false;
  /**
   * This callback can be invoked under three different circumstances:
   * - If the user closes the modal.
   * - If the user dismisses the modal by clicking the backdrop.
   * - If the user successfully creates a new customer account, after calling `onSave`.
   * @type {Function}
   */
  @bindable onClose = () => {};
  /**
   * This callback is invoked after the user successfully creates a new customer account.
   * @type {Function(account:Object)}
   */
  @bindable onSave = () => {};
  /**
   * Whether or not the form is loading the basic feature settings or saving the new customer
   * account.
   * @type {Boolean}
   */
  loading = false;
  /**
   * Whether or not the account creation was a success. This is also used by the container to
   * let the form know that the data was successfully saved and that it should consider any further
   * modification as a new change, since the form only enables the submit button when there are
   * changes.
   * @type {Boolean}
   */
  success = false;
  /**
   * When the modal gets opened (or resetted) this property will become a dictionary with the
   * information for the new account.
   * @type {?Object}
   */
  account = null;
  /**
   * When the modal gets opened for the first time, this property will contain the information
   * for the _"basic feature"_, the one that doesn't exist on the API but that was created for
   * the application to use for a customer basic information.
   * @type {?Object}
   */
  featureSettings = null;
  /**
   * This dictionary will be used to store Salesforce IDs' validations. The format will be: The
   * ID as the key, and whatever {@link Salesforce} returns as the value. In case an ID is not
   * valid, the value will be just `false`.
   * @type {Object}
   */
  salesforceValidations = {};
  /**
   * This property is kind of a hack for the form: The form only resets its information when
   * the `accountName` binding changes, but the issue here is that we don't have an account name,
   * so when a new account is created, we are unable to reset the form.
   * This variable will have a random value when the container is mounted and every time a new
   * account is created, its value will change.
   * @type {?Number}
   */
  operationId = null;
  /**
   * @param {AppErrorPublisher} appErrorPublisher To publish an error in case the creation
   *                                              process fails.
   * @param {CustomerAccounts}  customerAccounts  To actually create an account.
   * @param {errorParser}       errorParser       To parse errors.
   * @param {Features}          features          To get the settings information of the
   *                                              _"basic feature"_.
   * @param {Salesforce}        salesforce        To validate IDs.
   */
  constructor(
    appErrorPublisher,
    customerAccounts,
    errorParser,
    features,
    salesforce,
  ) {
    'inject';

    /**
     * A local reference for the `appErrorPublisher` service.
     * @type {AppErrorPublisher}
     * @access protected
     * @ignore
     */
    this._appErrorPublisher = appErrorPublisher;
    /**
     * A local reference for the `customerAccounts` service.
     * @type {CustomerAccounts}
     * @access protected
     * @ignore
     */
    this._customerAccounts = customerAccounts;
    /**
     * The parser the container will use to format possible errors.
     * @type {ParserrorWrapper}
     * @access protected
     * @ignore
     */
    this._newAccountModalsErrorParser = errorParser.wrap();
    /**
     * A local reference for the `features` service.
     * @type {Features}
     * @access protected
     * @ignore
     */
    this._features = features;
    /**
     * A local reference for the `salesforce` service.
     * @type {Salesforce}
     * @access protected
     * @ignore
     */
    this._salesforce = salesforce;
  }
  /**
   * This gets called every time the `open` binding changes. If the modal was closed and now it's
   * open, and there's no account information, load the feature settings and create a dictionary
   * to store the account information.
   * @param {Boolean} newValue The previous value of the `open` binding.
   * @param {Boolean} oldValue The current value of the `open` binding.
   */
  openChanged(newValue, oldValue) {
    if (!oldValue && newValue) {
      if (!this.account) {
        this._loadSettings();
      }
      if (this.operationId) {
        this.operationId = (new Date()).getTime();
      }
    }
  }
  /**
   * This is called from the form when the user wants to validate a Salesforce ID. It uses the
   * {@link Salesforce} service to make the validation, and then stores the results on the
   * container.
   * @param {String} value The Salesforce ID to validate.
   */
  validateSalesforce(value) {
    this._salesforce.getAccount(value)
    .then((validation) => {
      this._addSalesforceValidation(value, validation);
    })
    .catch((error) => {
      if (error.status === statuses['not found']) {
        this._addSalesforceValidation(value, false);
      } else {
        this._appErrorPublisher.publish(error);
      }
    });
  }
  /**
   * This method gets called when the user submits the form with the new customer account
   * information.
   * The method takes the _"changes"_ (because the form only provide the properties that changed
   * from the moment it recevied the account) and merges them on top of the `account` dictionary
   * before sending them to the service that handles the creation process.
   * After the account is saved, it informs the implementation.
   * @param {Object} changes The account information properties that changed inside the form.
   */
  createAccount(changes) {
    this.loading = true;
    this.success = false;
    const account = ObjectUtils.merge(this.account, changes);
    this._customerAccounts.createCustomerAccount(account)
    .then((savedAccount) => {
      this.account = null;
      this.operationId = (new Date()).getTime();
      this.onSave({ account: savedAccount });
      this.onClose();
      this.success = true;
    })
    .catch((error) => {
      const parsedError = this._newAccountModalsErrorParser(error);
      this._appErrorPublisher.notify(error, parsedError.message);
    })
    .then(() => {
      this.loading = false;
    });
  }
  /**
   * Whether or not the user can close the modal. Basically, don't close it if the container is
   * doing something.
   * @type {Boolean}
   */
  @computedFrom('loading')
  get canCloseTheModal() {
    return !this.loading;
  }
  /**
   * This is called when the modal is opened for the first time, or after a previous account was
   * created. The method will load the feature settings information (if needed) and create
   * dictionary for the form to use as _"account information"_.
   * @access protected
   * @ignore
   */
  _loadSettings() {
    // If the feature was already loaded before, just reset the account information.
    if (this.featureSettings) {
      this.account = this._features.getBasicFeatureDefaults();
    } else {
      this.loading = true;
      this._features.getBasicFeatureSettings()
      .then((featureSettings) => {
        this.featureSettings = featureSettings;
        this.account = this._features.getBasicFeatureDefaults();
      })
      .catch((error) => {
        const parsedError = this._newAccountModalsErrorParser(error);
        this._appErrorPublisher.notify(error, parsedError.message);
      })
      .then(() => {
        this.loading = false;
      });
    }
  }
  /**
   * Updates the validation results of a Salesforce account on the container dictionary.
   * @param {String} salesforceId The Salesforce account ID.
   * @param {*}      validation   The results of the validation.
   * @access protected
   * @ignore
   */
  _addSalesforceValidation(salesforceId, validation) {
    this.salesforceValidations = Object.assign(
      {},
      this.salesforceValidations,
      {
        [salesforceId]: validation,
      },
    );
  }
}

export { NewAccountModalContainer };
