import { boundMethod } from 'autobind-decorator';
/**
 * This would be the _"root of all containers"_, the one that setups the app basic functionalities
 * and renders all the other containers.
 */
class AppContainer {
  /**
   * @param {AppAPI}                      appAPI                      To be able to set the authorization token when the
   *                                                                  session gets updated.
   * @param {AppConfiguration}            appConfiguration            To get the app title and add it to the router.
   * @param {AppRouting}                  appRouting                  To setup the routes and the authorization step on
   *                                                                  the pipeline.
   * @param {appRequestsCache}            appRequestsCache            To clean the cache when the session ends.
   * @param {AppRoutingStep}              appRoutingStep              The authorization step that will validate the user
   *                                                                  permissions before accessing a route.
   * @param {AppSession}                  appSession                  To listen for session changes to update the UI.
   * @param {Intercom}                    intercom                    To trigger the Intercom integration.
   * @param {Router}                      router                      To update parameters.
   * @param {ApiAuthorizationInterceptor} apiAuthorizationInterceptor To trigger the Authorization Interceptor service.
   * @param {ApiVersionInterceptor}       apiVersionInterceptor       To trigger the Version Interceptor service.
   * @param {ViewMessages}                viewMessages                To send notifications to the user upon having
   *                                                                  version conflicts triggered by
   *                                                                  ApiVersionInterceptor.
   * @param {AppNavigation}               NAVIGATION                  To get all the routes the app will use.
   */
  constructor(
    appAPI,
    appConfiguration,
    appRequestsCache,
    appRouting,
    appRoutingStep,
    appSession,
    intercom,
    router,
    apiAuthorizationInterceptor,
    apiVersionInterceptor,
    viewMessages,
    NAVIGATION,
  ) {
    'inject';

    /**
     * A local reference for the `appAPI` service.
     * @type {AppAPI}
     * @access protected
     */
    this._appAPI = appAPI;
    /**
     * A local reference for the `appConfiguration` service.
     * @type {AppConfiguration}
     * @access protected
     */
    this._appConfiguration = appConfiguration;
    /**
     * A local reference for the `appRequestsCache` service.
     * @type {AppRequestsCache}
     * @access protected
     */
    this._appRequestsCache = appRequestsCache;
    /**
     * A local reference for the `appRouting` service.
     * @type {AppRouting}
     * @access protected
     */
    this._appRouting = appRouting;
    /**
     * A local reference for the `appRoutingStep` service.
     * @type {AppRoutingStep}
     * @access protected
     */
    this._appRoutingStep = appRoutingStep;
    /**
     * A local reference for the `appSession` service.
     * @type {AppSession}
     * @access protected
     */
    this._appSession = appSession;
    /**
     * A local reference for the `intercom` service.
     * @type {Intercom}
     * @access protected
     */
    this._intercom = intercom;
    /**
     * A local reference for the `apiAuthorizationInterceptor` service.
     * @type {ApiAuthorizationInterceptor}
     * @access protected
     */
    this._apiAuthorizationInterceptor = apiAuthorizationInterceptor;
    /**
     * A local reference for the `apiVersionInterceptor` service.
     * @type {ApiVersionInterceptor}
     * @access protected
     */
    this._apiVersionInterceptor = apiVersionInterceptor;
    /**
     * A local reference for the `viewMessages` service.
     * @type {ViewMessages}
     * @access protected
     */
    this._viewMessages = viewMessages;
    /**
     * A local reference to the app router.
     * @type {Router}
     * @access protected
     */
    this._router = router;
    /**
     * A local reference for the `NAVIGATION` constant.
     * @type {AppNavigation}
     * @access protected
     */
    this._NAVIGATION = NAVIGATION;
    /**
     * A list of subscriptions the container will create when activated. The idea of having all
     * of them on a single list is that when the component gets detched, it can "dispose" of
     * all of them with a simple `forEach`.
     * @access protected
     * @ginore
     */
    this._subscriptions = [];
    /**
     * Wether or not to show the menu on the UI.
     * @type {boolean}
     */
    this.showMenu = false;
  }
  /**
   * Starts listening for changes on the user session.
   */
  activate() {
    this._subscriptions = [
      this._appSession.onSessionLoaded(this._onSessionLoaded),
      this._appSession.onSessionCreated(this._onSessionLoaded),
      this._appSession.onSessionEnd(this._onSessionEnd),
      this._appAPI.addInterceptor(this._apiAuthorizationInterceptor),
      this._appAPI.addInterceptor(this._apiVersionInterceptor.interceptorWithAction(() => {
        this._viewMessages.notification('A new version has been released');
      })),
    ];
  }
  /**
   * Stops listening for changes on the user session.
   */
  detached() {
    this._subscriptions.forEach((subscription) => {
      subscription.dispose();
    });
  }
  /**
   * Add the authorization step on the router pipeline and define all the app routes.
   * @param {RouterConfiguration} config The router configuration.
   */
  configureRouter(config) {
    config.title = this._appConfiguration.get('appHTMLTitle');
    config.addPipelineStep('authorize', this._appRoutingStep);
    config.options.pushState = true;
    config.map(this._appRouting.extractRoutes(this._NAVIGATION));
  }
  /**
   * This gets called when a session is created or loaded from the storage. The method takes
   * the information from the session and it updates the menu flag, sets the token for the API and
   * boots the Intercom integration.
   * @access protected
   * @ignore
   */
  @boundMethod
  _onSessionLoaded() {
    this.showMenu = true;
    const session = this._appSession.getSession();
    this._appAPI.setAuthorizationToken(session.token);
    this._intercom.boot(session.user);
  }
  /**
   * This gets called when a session is terminated, it updates the menu flag, removes the token,
   * cleans the request cache and shutdowns the Intercom integration.
   * @access protected
   * @ignore
   */
  @boundMethod
  _onSessionEnd() {
    this.showMenu = false;
    this._appAPI.setAuthorizationToken();
    this._appRequestsCache.clean();
    this._intercom.shutdown();
  }
}

export { AppContainer };
