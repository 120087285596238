import { bindable, computedFrom } from 'aurelia-framework';
// to avoid no-magic-numbers
const defaultTimeBeforeEnablingAnimations = 1750;
/**
 * This _"sidebar"_ shows one component and when a flag changes, it slides to show
 * another component.
 * For example: On the first component it can show a list of elements, and when the
 * user selects one, it could slide to show the options for the selected element.
 */
class SidebarSelector {
  /**
   * Whether or not the user can toggle the sidebar size.
   * @type {Boolean}
   */
  @bindable toggleable = true;
  /**
   * Whether or not to show a loading indicator.
   * @type {Boolean}
   */
  @bindable loading = false;
  /**
   * The title for the first component.
   * @type {String}
   */
  @bindable selectorTitle = 'List';
  /**
   * The title for the second component.
   * @type {String}
   */
  @bindable optionsTitle = 'Options';
  /**
   * Whether or not the second component should be visible.
   * @type {Boolean}
   */
  @bindable hasSelection = false;
  /**
   * Whether or not the sidebar should be open.
   * @type {Boolean}
   */
  @bindable open = true;
  /**
   * How much time should the component wait until animations are enabled. The reason for this is
   * a case in which the app needs to load with the sidebar already showing the options; if
   * animations were enabled all the time, the user would see the sidebar load the first screen
   * and then move to the options, and it would look kind of weird. Using this, the user won't
   * see the component move to the options when the app loads.
   * @type {Number}
   */
  @bindable timeBeforeEnablingAnimations = defaultTimeBeforeEnablingAnimations;
  /**
   * Callback for when the user clicks opens/closes the sidebar.
   * @type {Function(open:Boolean)}
   */
  @bindable onToggle = () => {};
  /**
   * This is a flag used to tell the view that the component is ready to be used and
   * that animations can be enabled, so we can avoid animations being fired before
   * the user has any chance to do anything.
   * @type {Boolean}
   */
  ready = false;
  /**
   * When the component gets attached, it sets the timer to eventually enable animations.
   */
  attached() {
    setTimeout(() => {
      this.ready = true;
    }, this.timeBeforeEnablingAnimations);
  }
  /**
   * Toggles the status of the sidebar and informs the implementation about the
   * change using the `onToggle` callback.
   */
  toggleSidebar() {
    this.open = !this.open;
    this.onToggle({
      open: this.open,
    });
  }
  /**
   * Whether or not the sidebar is open. The reason the check is made through a getter is because
   * if the `toggleable` property is set to false, then the sidebar is _"always open"_.
   */
  @computedFrom('toggleable', 'open')
  get isOpen() {
    return this.toggleable ? true : this.open;
  }
}

export { SidebarSelector };
